/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { ICartItem } from "src/models";
import { useMartCart } from "src/redux/hooks";

interface IProps {
  cartItem: ICartItem;
}

const ItemCart: React.FC<IProps> = ({ cartItem }) => {
  const { removeItem, updateCartItem } = useMartCart();
  const {
    product: { price, images, title, qtyPerUnit, discount, id },
    quantity,
  } = cartItem;
  const linkTo = `/product-detail/${id}`;

  const incrementQuantity = () => {
    updateCartItem({ ...cartItem, quantity: quantity + 1 });
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      updateCartItem({ ...cartItem, quantity: quantity - 1 });
    }
  };

  return (
    <tr className="pt-30" key={id}>
      <td className="custome-checkbox pl-30">
        <input
          className="form-check-input"
          type="checkbox"
          name="checkbox"
          id="exampleCheckbox1"
          value=""
        />
        <label className="form-check-label" htmlFor="exampleCheckbox1"></label>
      </td>
      <td className="image product-thumbnail pt-40">
        <img src={config.BASE_URL + images[0].path} alt={title} />
      </td>
      <td className="product-des product-name">
        <h6 className="mb-5">
          <Link className="product-name mb-10 text-heading" to={linkTo}>
            {title} {qtyPerUnit}
          </Link>
        </h6>
        <div className="product-rate-cover">
          <div className="product-rate d-inline-block">
            <div className="product-rating" style={{ width: "90%" }}></div>
          </div>
          <span className="font-small ml-5 text-muted"> (4.0)</span>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h4 className="text-body">
          Rs {Utils.getDicountPrice(price, discount)}{" "}
        </h4>
      </td>
      <td className="text-center detail-info" data-title="Stock">
        <div className="detail-extralink mr-15">
          <div className="detail-qty border radius">
            <a onClick={decrementQuantity} className="qty-down">
              <i className="fi-rs-angle-small-down"></i>
            </a>
            <span className="qty-val">{quantity}</span>
            <a onClick={incrementQuantity} className="qty-up">
              <i className="fi-rs-angle-small-up"></i>
            </a>
          </div>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h4 className="text-brand">
          Rs
          {Utils.getDicountPrice(price, discount) * quantity}
        </h4>
      </td>
      <td className="action text-center" data-title="Remove">
        <a onClick={() => removeItem(id)} className="text-body">
          <i className="fi-rs-trash"></i>
        </a>
      </td>
    </tr>
  );
};

export default ItemCart;
