import { IAxiosResponse, IProduct } from "src/models";
import axiosInstance from "./axios";

export default class MartCartService {
  private static readonly url: string = "/cart";
  public static async findAllProducts(
    ids: number[]
  ): Promise<IAxiosResponse<IProduct[]>> {
    const response = await axiosInstance.post(`${this.url}/products`, { ids });
    return response.data;
  }
}
