import React from "react";

const PrivacyPolicy = () => {
  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5"></i>Home
            </a>
            <span></span> Pages
            <span></span> Privacy Policy
          </div>
        </div>
      </div>
      <div className="page-content pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <div className="row">
                <div className="col-lg-9">
                  <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                    <div className="single-header  style-2">
                      <h2>Privacy Policy</h2>
                      <div className="entry-meta meta-1 meta-3 font-xs mt-15 mb-15">
                        <span className="post-by">
                          By <a href="#">Jonh</a>
                        </span>
                        <span className="post-on has-dot">9 April 2020</span>
                        <span className="time-reading has-dot">
                          8 mins read
                        </span>
                        <span className="hit-count  has-dot">29k Views</span>
                      </div>
                    </div>
                    <div className="single-content mb-50">
                      <h4>Welcome to AliThemes’s Privacy Policy</h4>
                      <ol start={1}>
                        <li>
                          Hi there, we’re AliThemes Pty Ltd (ABN 11 119 159 741)
                          of Level 1, 121 King Street Melbourne, 3000, Australia
                          (“<strong>AliThemes</strong>”) and welcome to our
                          privacy policy which also applies to our Affiliate
                          Companies. This policy sets out how we handle your
                          personal information if you’re an AliThemes user or
                          visitor to our Sites. It applies across AliThemes
                          Elements, AliThemes Market, AliThemes Twenty20,
                          AliThemes Studio, AliThemes Sites, AliThemes Tuts+ and
                          Placeit (the “<strong>Sites</strong>”).
                        </li>
                        <li>
                          When we say ‘we’, ‘us’ or ‘AliThemes’ it’s because
                          that’s who we are and we own and run the Sites.
                        </li>
                        <li>
                          If we say ‘policy’ we’re talking about this privacy
                          policy. If we say ‘user terms’ we’re talking about the
                          rules for using each of the Sites. The rules vary by
                          product and each product makes them separately
                          available and seeks consent to them separately to this
                          policy.
                        </li>
                      </ol>
                      <h4>The type of personal information we collect</h4>
                      <ol start={4}>
                        <li>
                          We collect certain personal information about visitors
                          and users of our Sites.
                        </li>
                        <li>
                          The most common types of information we collect
                          include things like: user-names, member names, email
                          addresses, IP addresses, other contact details, survey
                          responses, blogs, photos, payment information such as
                          payment agent details, transactional details, tax
                          information, support queries, forum comments (if
                          applicable), content you direct us to make available
                          on our Sites (such as item descriptions), your actions
                          on our Sites (including any selections or inputs into
                          items) and web and email analytics data. We will also
                          collect personal information from job applications
                          (such as, your CV, the application form itself, cover
                          letter and interview notes).
                        </li>
                      </ol>
                      <h4>How we collect personal information</h4>
                      <ol start={6}>
                        <li>
                          We collect personal information directly when you
                          provide it to us, automatically as you navigate
                          through the Sites, or through other people when you
                          use services associated with the Sites.
                        </li>
                        <li>
                          We collect your personal information when you provide
                          it to us when you complete membership registration and
                          buy or provide items or services on our Sites,
                          subscribe to a newsletter, email list, submit
                          feedback, enter a contest, fill out a survey, or send
                          us a communication.
                        </li>
                        <li>
                          As the operator of digital content marketplaces, we
                          have a legitimate interest in verifying the identity
                          of our authors. We believe that knowing who our
                          authors are will strengthen the integrity of our
                          marketplaces by reducing fraud, making authors more
                          accountable for their content and giving AliThemes and
                          customers the ability to enforce contracts for authors
                          who break the rules. AliThemes also has certain legal
                          obligations that require us to know who our authors
                          are in certain circumstances. In light of this, if you
                          are an author we will verify your identity, in
                          particular, your name, full address and date of birth
                          by asking you to show us a Photo ID document. The
                          verification procedure is called AliThemes Verify.
                          AliThemes Verify will be carried out by our service
                          provider Jumio Corporation located at 395 Page Mill
                          Road, Suite 150, Palo Alto, California, USA. As part
                          of AliThemes Verify, you will be asked to take a
                          selfie which will then be compared against your Photo
                          ID document using facial recognition technology. We
                          will use your Photo ID document solely to carry out
                          AliThemes Verify and delete it after the completion of
                          this process.
                        </li>
                      </ol>
                      <h4>
                        Personal information we collect about you from others
                      </h4>
                      <ol start={9}>
                        <li>
                          Although we generally collect personal information
                          directly from you, on occasion, we also collect
                          certain categories of personal information about you
                          from other sources. In particular:
                          <ol>
                            <li>
                              financial and/or transaction details from payment
                              providers located in the US, UK, and Australia in
                              order to process a transaction;
                            </li>
                            <li>
                              third party service providers (like Google,
                              Facebook) who are located in the US or UK, which
                              may provide information about you when you link,
                              connect, or login to your account with the third
                              party provider and they send us information such
                              as your registration and profile from that
                              service, this only occurs if you are using
                              AliThemes Studio. The information varies and is
                              controlled by that service provider or as
                              authorized by you via your privacy settings at
                              that service provider; and
                            </li>
                            <li>
                              other third party sources/and or partners from
                              Australia, US or UK, whereby we receive additional
                              information about you (to the extent permitted by
                              applicable law), such as demographic data or fraud
                              detection information, and combine it with
                              information we have about you. For example, fraud
                              warnings from service providers like identity
                              verification service. We also receive information
                              about you and your activities on and off the
                              AliThemes platform through partnerships, or about
                              your experiences and interactions from our partner
                              ad networks. We also receive information about you
                              as a rights holder from our third party authors.
                              For example, information in the form of a model
                              release when your image is used in an item made
                              available on our Sites.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <h4>How we use personal information</h4>
                      <ol start={10}>
                        <li>
                          We will use your personal information:
                          <ol>
                            <li>
                              To fulfil a contract, or take steps linked to a
                              contract: in particular, in facilitating and
                              processing transactions that take place on the
                              Sites, like where you purchase an item from our
                              marketplace.
                            </li>
                            <li>
                              Where this is necessary for purposes which are in
                              our, or third parties’, legitimate interests.
                              These interests include:
                              <ol>
                                <li>operating the Sites;</li>
                                <li>
                                  providing you with services described on the
                                  Sites;
                                </li>
                                <li>
                                  verifying your identity when you sign in to
                                  any of our Sites;
                                </li>
                                <li>
                                  responding to support tickets, and helping
                                  facilitate the resolution of any disputes;
                                </li>
                                <li>
                                  updating you with operational news and
                                  information about our Sites and services e.g.
                                  to notify you about changes to our Sites,
                                  website disruptions or security updates;
                                </li>
                                <li>
                                  carrying out technical analysis to determine
                                  how to improve the Sites and services we
                                  provide;
                                </li>
                                <li>
                                  monitoring activity on the Sites, e.g. to
                                  identify potential fraudulent activity and to
                                  ensure compliance with the user terms that
                                  apply to the Sites;
                                </li>
                                <li>
                                  managing our relationship with you, e.g. by
                                  responding to your comments or queries
                                  submitted to us on the Sites or asking for
                                  your feedback or whether you want to
                                  participate in a survey;
                                </li>
                                <li>
                                  managing our legal and operational affairs
                                  (including, managing risks relating to content
                                  and fraud matters);
                                </li>
                                <li>
                                  training AliThemes staff about how to best
                                  serve our user community;
                                </li>
                                <li>improving our products and services;</li>
                                <li>
                                  providing general administrative and
                                  performance functions and activities; and
                                </li>
                                <li>
                                  verifying your identity by comparing a selfie
                                  taken by you against your Photo ID document
                                  using facial recognition technology (as is
                                  necessary for the establishment, exercise or
                                  defense of legal claims); and
                                </li>
                                <li>
                                  processing your job application to AliThemes.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Where you give us consent:
                              <ol>
                                <li>
                                  providing you with marketing information about
                                  products and services which we feel may
                                  interest you; and
                                </li>
                                <li>
                                  customising our services and websites, like
                                  advertising that appear on the Site – where
                                  this involves the use of cookies or similar
                                  technologies – in order to provide a more
                                  personalised experience.
                                </li>
                              </ol>
                            </li>
                            <li>For purposes which are required by law.</li>
                            <li>
                              For the purpose of responding to requests by
                              government, a court of law, or law enforcement
                              authorities conducting an investigation.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <h4>When we disclose your personal information</h4>
                      <ol start={11}>
                        <li>
                          We will disclose personal information to the following
                          recipients:
                          <ol>
                            <li>
                              companies that are in the AliThemes group which
                              are located in Australia, Mexico and the US;
                            </li>
                            <li>
                              if applicable, authors of any items or services
                              made available to you, so they can facilitate
                              support and license validation, who maybe located
                              in any of the countries our products are available
                              in;
                            </li>
                            <li>
                              subcontractors and service providers who assist us
                              in connection with the ways we use personal
                              information (as set out above), in particular:
                              website hosting providers which are located in
                              Australia, US and UK; technical and customer
                              support services which are located in Australia,
                              Canada, Philippines, Poland, Mexico, Romania, UK
                              and the US; recruitment agencies which are located
                              in Australia, US and Mexico; marketing and
                              analytics services which are located in the US;
                              security and fraud prevention services which are
                              located in the US; subscription management
                              services which are located in the US; payment
                              processing services which are located in the US,
                              UK and Australia; identification verification
                              services located in the UK; and operational
                              tooling services which are located in the US.
                              Noting that our subcontractors and services
                              providers may also transfer and access such
                              information from other countries in which they
                              have operations.
                            </li>
                            <li>
                              our professional advisers (lawyers, accountants,
                              financial advisers etc.) which are located in
                              Australia, Ireland, Mexico, UK and USA;
                            </li>
                            <li>
                              regulators and government authorities in
                              connection with our compliance procedures and
                              obligations;
                            </li>
                            <li>
                              a purchaser or prospective purchaser of all or
                              part of our assets or our business, and their
                              professional advisers, in connection with the
                              purchase;
                            </li>
                            <li>
                              a third party to respond to requests relating to a
                              criminal investigation or alleged or suspected
                              illegal activity;
                            </li>
                            <li>
                              a third party, in order to enforce or defend our
                              rights, or to address financial or reputational
                              risks;
                            </li>
                            <li>
                              a rights holder in relation to an allegation of
                              intellectual property infringement or any other
                              infringement; and
                            </li>
                            <li>
                              other recipients where we are authorised or
                              required by law, or requests by government, a
                              court of law, or law enforcement authorities, to
                              do so.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <h4>
                        Where we transfer and/or store your personal information
                      </h4>
                      <ol start={12}>
                        <li>
                          We are based in Australia and Mexico so your data will
                          be processed in Australia, Mexico and the US. Some of
                          the recipients we have described in section 10 above,
                          and to whom we disclose your personal information, are
                          based in places like Australia, Canada, Ireland,
                          Mexico, Philippines, Poland, Romania, UK, the US,
                          India and Columbia. We do this on the basis of this
                          policy. In order to protect your information, we take
                          care where possible to work with subcontractors and
                          service providers who we believe maintain an
                          acceptable standard of data security compliance.
                        </li>
                      </ol>
                      <h4>How we keep your personal information secure</h4>
                      <ol start={13}>
                        <li>
                          We store personal information on secure servers that
                          are managed by us and our service providers, and
                          occasionally hard copy files that are kept in a secure
                          location in Australia, Ireland, Mexico and the US.
                          Personal information that we store or transmit is
                          protected by security and access controls, including
                          username and password authentication, two-factor
                          authentication, and data encryption where appropriate.
                        </li>
                      </ol>
                      <h4>How you can access your personal information</h4>
                      <ol start={14}>
                        <li>
                          You can access some of the personal information that
                          we collect about you by logging in to your account.
                          You also have the right to make a request to access
                          other personal information we hold about you and to
                          request corrections of any errors in that data. You
                          can also close the account you have with us for any of
                          our Sites at any time. To make an access or correction
                          request, contact our privacy champion using the
                          contact details at the end of this policy.
                        </li>
                      </ol>
                      <h4>
                        Marketing Choices regarding your personal information
                      </h4>
                      <ol start={15}>
                        <li>
                          Where we have your consent to do so (e.g. if you have
                          subscribed to one of our email lists or have indicated
                          that you are interested in receiving offers or
                          information from us), we send you marketing
                          communications by email about products and services
                          that we feel may be of interest to you. You can
                          ‘opt-out’ of such communications if you would prefer
                          not to receive them in the future by using the
                          “unsubscribe” facility provided in the communication
                          itself.
                        </li>
                        <li>
                          You also have choices about cookies, as described
                          below. By modifying your browser preferences, you have
                          the choice to accept all cookies, to be notified when
                          a cookie is set, or to reject all cookies. If you
                          choose to reject cookies some parts of our Sites may
                          not work properly in your case.
                        </li>
                      </ol>
                      <h4>Cookies (not the type you eat!) and web analytics</h4>
                      <ol start={17}>
                        <li>
                          For more information about how we use cookies, web
                          beacons and similar technologies see our cookie policy{" "}
                          <a href="https://alithemes.com/cookies/">here</a> and
                          for more general information on cookies, see{" "}
                          <a
                            href="http://www.allaboutcookies.org/"
                            rel="noopener noreferrer"
                          >
                            http://www.allaboutcookies.org
                          </a>
                          .
                        </li>
                        <li>
                          When you visit our Sites, there’s certain information
                          that’s recorded which is generally anonymous
                          information and does not reveal your identity. If
                          you’re logged into your account some of this
                          information could be associated with your account.
                          We’re talking about the following kinds of details:
                          <ol>
                            <li>
                              your IP address or proxy server IP address’;
                            </li>
                            <li>the domain name you requested;</li>
                            <li>
                              the name of your internet service provider is
                              sometimes captured depending on the configuration
                              of your ISP connection;
                            </li>
                            <li>
                              the date and time of your visit to the website;
                            </li>
                            <li>the length of your session;</li>
                            <li>the pages which you have accessed;</li>
                            <li>
                              the number of times you access our site within any
                              month;
                            </li>
                            <li>
                              the file URL you look at and information relating
                              to it;
                            </li>
                            <li>
                              the website which referred you to our Sites;
                            </li>
                            <li>
                              the operating system which your computer uses; and
                            </li>
                            <li>
                              the technical capabilities of your web browser.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Occasionally, we will use third party advertising
                          companies to serve ads based on prior visits to our
                          Sites. For example, if you visit our Sites, you may
                          later see an add for our products and services when
                          you visit a different Site. Read more about your
                          options in our{" "}
                          <a href="https://alithemes.com/cookies/">
                            cookie policy
                          </a>
                          .
                        </li>
                      </ol>
                      <h4>Information about children</h4>
                      <ol start={20}>
                        <li>
                          Our Sites are not suitable for children under the age
                          of 16 years, so if you are under 16 we ask that you do
                          not use our Sites or give us your personal information
                          (if you are a young tech wiz, please direct your
                          nearest responsible adult to use the Sites for you!).
                          If you are from 16 to 18 years, you can browse the
                          Sites but you’ll need the supervision of a parent or
                          guardian to become a registered user. It’s the
                          responsibility of parents or guardians to monitor
                          their children’s use of our Sites.
                        </li>
                      </ol>
                      <h4>Information you make public or give to others</h4>
                      <ol start={21}>
                        <li>
                          If you make your personal information available to
                          other people, we can’t control or accept
                          responsibility for the way they will use or manage
                          that data. There are lots of ways that you can find
                          yourself providing information to other people, like
                          when you post a public message on a forum thread,
                          share information via social media, or make contact
                          with another user (such as a third party Author)
                          whether via our Sites or directly via email. Before
                          making your information publicly available or giving
                          your information to anyone else, think carefully. If
                          giving information to another user via our Sites, ask
                          them how they will handle your information. If you’re
                          sharing information via another website, check the
                          privacy policy for that site to understand its
                          information management practices as this privacy
                          policy will not apply.
                        </li>
                      </ol>
                      <h4>How long we keep your personal information</h4>
                      <ol start={22}>
                        <li>
                          We retain your personal information for as long as is
                          necessary to provide the services to you and others,
                          and to comply with our legal obligations. If you no
                          longer want us to use your personal information or to
                          provide you with the AliThemes services, you can
                          request that we erase your personal information and
                          close your AliThemes account. Please note that if you
                          request the erasure of your personal information we
                          will retain information from deleted accounts as
                          necessary for our legitimate business interests, to
                          comply with the law, prevent fraud, collect fees,
                          resolve disputes, troubleshoot problems, assist with
                          investigations or requests by government, a court of
                          law, or law enforcement authorities, enforce the terms
                          of service and take other actions permitted by law.
                          The information we retain will be handled in
                          accordance with this Privacy Policy.
                        </li>
                      </ol>
                      <h4>When we need to update this policy</h4>
                      <ol start={23}>
                        <li>
                          We will need to change this policy from time to time
                          in order to make sure it stays up to date with the
                          latest legal requirements and any changes to our
                          privacy management practices.
                        </li>
                        <li>
                          When we do change the policy, we’ll make sure to
                          notify you about such changes, where required. A copy
                          of the latest version of this policy will always be
                          available on this page.
                        </li>
                      </ol>
                      <h4>How you can contact us</h4>
                      <ol start={25}>
                        <li>
                          If you have any questions about our privacy practices
                          or the way in which we have been managing your
                          personal information, please contact our privacy
                          champion in writing at PO Box 16122 Collins Street
                          West Victoria 8007 Australia or{" "}
                          <a href="cdn-cgi/l/email-protection.html#f282809b8493918bdc919a939f829b9d9cb2b39e9ba69a979f9781dc919d9f">
                            <span
                              className="__cf_email__"
                              data-cfemail="addddfc4dbccced483cec5ccc0ddc4c2c3edecc1c4f9c5c8c0c8de83cec2c0"
                            >
                              [email&#160;protected]
                            </span>
                          </a>
                          .
                        </li>
                        <li>
                          We’re really glad you made it to the end of the
                          privacy policy, because knowing this stuff is the best
                          way to understand how your personal information is
                          used and how to best manage it!
                        </li>
                      </ol>
                      <h4>
                        If you’re a user or visitor in the European Economic
                        Area these rights also apply to you:
                      </h4>
                      <ol start={1}>
                        <li>
                          For the purposes of applicable EU data protection law
                          (including the General Data Protection Regulation
                          2016/679 (the “<strong>GDPR</strong>”), we are a ‘data
                          controller’ of your personal information.
                        </li>
                      </ol>
                      <h4>How you can access your personal information</h4>
                      <ol start={2}>
                        <li>
                          You are also entitled to ask us to port your personal
                          information (i.e. to transfer in a structured,
                          commonly used and machine-readable format, to you), to
                          erase it, or restrict its processing. You also have
                          rights to object to some processing that is based on
                          our legitimate interests, such as profiling that we
                          perform for the purposes of direct marketing, and,
                          where we have asked for your consent to process your
                          data, to withdraw this consent as more fully described
                          below.
                        </li>
                        <li>
                          These rights are limited in some situations – for
                          example, we can demonstrate that we have a legal
                          requirement to process your personal information. In
                          some instances, this means that we may retain some
                          data even if you withdraw your consent.
                        </li>
                        <li>
                          Where we require your personal information to comply
                          with legal or contractual obligations, then provision
                          of such data is mandatory: if such data is not
                          provided, then we will not be able to manage our
                          contractual relationship with you, or to meet
                          obligations placed on us. In all other cases,
                          provision of requested personal information is
                          optional.
                        </li>
                        <li>
                          If you have unresolved concerns you also have the
                          right to complain to data protection authorities. The
                          relevant data protection authority will be the data
                          protection authority of the country: (i) of your
                          habitual residence; (ii) of your place of work; or
                          (iii) in which you consider the alleged infringement
                          has occurred.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 primary-sidebar sticky-sidebar">
                  <div className="widget-area">
                    <div className="sidebar-widget-2 widget_search mb-50">
                      <div className="search-form">
                        <form action="#">
                          <input type="text" placeholder="Search…" />
                          <button type="submit">
                            {" "}
                            <i className="fi-rs-search"></i>{" "}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar-widget widget-category-2 mb-30">
                      <h5 className="section-title style-1 mb-30 wow fadeIn animated">
                        Category
                      </h5>
                      <ul>
                        <li>
                          <a href="shop-grid-right.html">
                            {" "}
                            <img
                              src="assets/imgs/theme/icons/category-1.svg"
                              alt=""
                            />
                            Milks & Dairies
                          </a>
                          <span className="count">30</span>
                        </li>
                        <li>
                          <a href="shop-grid-right.html">
                            {" "}
                            <img
                              src="assets/imgs/theme/icons/category-2.svg"
                              alt=""
                            />
                            Clothing
                          </a>
                          <span className="count">35</span>
                        </li>
                        <li>
                          <a href="shop-grid-right.html">
                            {" "}
                            <img
                              src="assets/imgs/theme/icons/category-3.svg"
                              alt=""
                            />
                            Pet Foods{" "}
                          </a>
                          <span className="count">42</span>
                        </li>
                        <li>
                          <a href="shop-grid-right.html">
                            {" "}
                            <img
                              src="assets/imgs/theme/icons/category-4.svg"
                              alt=""
                            />
                            Baking material
                          </a>
                          <span className="count">68</span>
                        </li>
                        <li>
                          <a href="shop-grid-right.html">
                            {" "}
                            <img
                              src="assets/imgs/theme/icons/category-5.svg"
                              alt=""
                            />
                            Fresh Fruit
                          </a>
                          <span className="count">87</span>
                        </li>
                      </ul>
                    </div>
                    {/* Product sidebar Widget  */}
                    <div className="sidebar-widget product-sidebar  mb-30 p-30 bg-grey border-radius-10">
                      <h5 className="section-title style-1 mb-30 wow fadeIn animated">
                        Trending Now
                      </h5>
                      <div className="single-post clearfix">
                        <div className="image">
                          <img src="assets/imgs/shop/thumbnail-3.jpg" alt="#" />
                        </div>
                        <div className="content pt-10">
                          <h5>
                            <a href="shop-product-detail.html">Chen Cardigan</a>
                          </h5>
                          <p className="price mb-0 mt-5">$99.50</p>
                          <div className="product-rate">
                            <div
                              className="product-rating"
                              style={{ width: "90%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="single-post clearfix">
                        <div className="image">
                          <img src="assets/imgs/shop/thumbnail-4.jpg" alt="#" />
                        </div>
                        <div className="content pt-10">
                          <h6>
                            <a href="shop-product-detail.html">Chen Sweater</a>
                          </h6>
                          <p className="price mb-0 mt-5">$89.50</p>
                          <div className="product-rate">
                            <div
                              className="product-rating"
                              style={{ width: "80%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="single-post clearfix">
                        <div className="image">
                          <img src="assets/imgs/shop/thumbnail-5.jpg" alt="#" />
                        </div>
                        <div className="content pt-10">
                          <h6>
                            <a href="shop-product-detail.html">
                              Colorful Jacket
                            </a>
                          </h6>
                          <p className="price mb-0 mt-5">$25</p>
                          <div className="product-rate">
                            <div
                              className="product-rating"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="single-post clearfix">
                        <div className="image">
                          <img src="assets/imgs/shop/thumbnail-6.jpg" alt="#" />
                        </div>
                        <div className="content pt-10">
                          <h6>
                            <a href="shop-product-detail.html">Lorem, ipsum</a>
                          </h6>
                          <p className="price mb-0 mt-5">$25</p>
                          <div className="product-rate">
                            <div
                              className="product-rating"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="banner-img wow fadeIn mb-40 animated d-lg-block d-none">
                      <img src="assets/imgs/banner/banner-11.png" alt="" />
                      <div className="banner-text">
                        <span>Oganic</span>
                        <h4>
                          Save 17% <br /> on{" "}
                          <span className="text-brand">Oganic</span>
                          <br /> Juice
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
