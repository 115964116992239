/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useProducts } from "src/redux/hooks";
import ItemDiscountProduct from "../../shop/components/ItemDiscountProduct";

const DealsOfTheDay = () => {
  const { isDiscountProductLoaded, discountProducts, loadDiscountProducts } =
    useProducts();

  useEffect(() => {
    (async () => {
      if (!isDiscountProductLoaded) {
        await loadDiscountProducts();
      }
    })();
  }, [isDiscountProductLoaded]);
  return (
    <section className="section-padding pb-5">
      <div className="container">
        <div className="section-title">
          <h3 className="">Deals Of The Day</h3>
          {/* <a className="show-all" href="shop-grid-right.html">
            All Deals
            <i className="fi-rs-angle-right"></i>
          </a> */}
        </div>
        <div className="row">
          {discountProducts.map((product) => (
            <ItemDiscountProduct product={product} key={product.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default DealsOfTheDay;
