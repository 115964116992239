import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { MartWishlistActions } from "src/redux/actions";
import { MartWishlistService } from "src/redux/services";
import { IProduct } from "src/models";
const useMartWishlist = () => {
  const dispatch = useDispatch();
  const {
    martWebsiteAuth: { isLoggedIn },
    martWishlist: { isLoaded, products },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const ids = products.map((product) => product.id);

  const findAll = async () => {
    try {
      setLoading(true);
      const response = await MartWishlistService.findAllProducts(ids);
      const products = response.response;
      if (products) {
        dispatch(MartWishlistActions.load(products));
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    dispatch(MartWishlistActions.clear());
  };

  const addWishlistItem = (product: IProduct) => {
    dispatch(MartWishlistActions.addItem(product));
  };

  const removeWishlistItem = (id: number) => {
    dispatch(MartWishlistActions.removeItem(id));
  };

  const isWishlisted = (id: number): boolean => {
    return products.find((product) => product.id === id) !== undefined;
  };

  return {
    loading,
    isLoggedIn,
    isLoaded,
    products,
    size: products.length,
    isEmpty: products.length === 0,
    isWishlisted,
    clear,
    findAll,
    addWishlistItem,
    removeWishlistItem,
  };
};

export default useMartWishlist;
