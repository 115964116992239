import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { DeliveryChargesService } from "src/redux/services";
import { DeliveryChargeActions } from "../actions";
const useDeliveryCharges = () => {
  const dispatch = useDispatch();
  const {
    deliveryCharges: { deliveryCharge, isLoaded },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const getDeliveryCharges = async () => {
    try {
      setLoading(true);

      const response = await DeliveryChargesService.getDeliceryCharges();
      const deliveryCharges = response.response;
      deliveryCharges && dispatch(DeliveryChargeActions.load(deliveryCharges));
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    deliveryCharge,
    isLoaded,
    getDeliveryCharges,
  };
};

export default useDeliveryCharges;
