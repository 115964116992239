/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { Breadcrumb, MapComponent2, Modal } from "src/components";
import { IAddress, IOrder, IOrderItem } from "src/models";
import {
  useAddress,
  useAuth,
  useMartCart,
  useMartOrders,
} from "src/redux/hooks";
import CheckOutOrderDetail from "./components/CheckOutOrderDetail";
import * as Yup from "yup";
import { useFormik } from "formik";

import swal from "sweetalert";

const Checkout = () => {
  const { size, items, total, deliveryChargeAmount } = useMartCart();
  const { save } = useMartOrders();

  const { user, isLoggedIn } = useAuth();
  const { getDefaultAddress, addresses, findAll, isLoaded } = useAddress();
  const [address, setAddress] = useState<IAddress | undefined>(
    getDefaultAddress()
  );
  const [showAddressMap, setShowAddressMap] = useState<boolean>(false);

  const initialValues: IAddress = {
    id: 0,
    name: user?.name || "",
    phone: user?.phone || "",
    address: "",
    city: "",
    email: "",
    latitude: 0,
    longitude: 0,
    apartment: "",
    isDefault: false,
    UserId: user?.id!,
    gender: "Mr",
    type: "Home",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    apartment: Yup.string().required("Apartment detail is required"),
    address: Yup.string().required("Address is required"),
  });

  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);

  const onAddressChange = (e: any) => {
    const { value } = e.target;
    setAddress(addresses.find((a) => "" + a.id === value));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const orderItems = items.map(({ product, quantity }) => ({
        price: product.price,
        discount: product.discount,
        quantity: quantity,
        content: "",
        ProductId: product.id,
      }));

      const order: IOrder = {
        orderStatus: "New",
        subTotal: total,
        total: total,
        deliveryCharges: deliveryChargeAmount,
        grandTotal: total,
        OrderItems: orderItems as IOrderItem[],
        UserId: user?.id!,
        isReceived: false,
        isCancel: false,
        address: values.address,
        apartment: values.apartment,
        city: values.city,
        longitude: values.longitude,
        latitude: values.latitude,
        name: values.name,
        phone: values.phone,
        shipping: 0,
        tax: 0,
        id: 0,
      };
      swal({
        title: `Are you sure to place order?`,
        text: order.address,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willSave) => {
        if (willSave) {
          await save(
            order,
            () => {
              swal(`Success! Your order has been placed successfully!`, {
                icon: "success",
              });
            },
            (error: string) => {
              swal(`OOPS! ${error}. try to use another address!`, {
                icon: "error",
              });
            }
          );
        }
      });
    },
  });

  const { errors, touched, values, handleSubmit } = formik;

  useEffect(() => {
    if (address) {
      formik.setValues({
        ...formik.values,
        ...address,
      });
    }
  }, [address]);

  useEffect(() => {
    setAddress(getDefaultAddress());
  }, [addresses]);

  const onMapAddressChange = (address: IAddress) => {
    setAddress(address);
  };

  const getFieldError = (fieldName: string) => {
    const hasError = errors[fieldName];
    const hastouched = touched[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };

  return (
    <main className="main">
      <Breadcrumb pages={["Shop", "Checkout"]} />
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-8 mb-40">
            <h1 className="heading-2 mb-10">Checkout</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand">{size}</span> products in
                your cart
              </h6>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <h4 className="mb-30">Billing Details</h4>

                {address ? (
                  <Fragment>
                    <div className="row">
                      {addresses.length > 0 && (
                        <div className="form-group col-lg-12">
                          <label>Change Billing Address</label>
                          <select
                            value={address?.id}
                            onChange={onAddressChange}
                          >
                            {addresses.map((address) => (
                              <option value={address.id}>
                                {address.apartment} , {address.address}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          placeholder="Name *"
                          {...formik.getFieldProps("name")}
                        />
                        {getFieldError("name")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          placeholder="Address *"
                          {...formik.getFieldProps("address")}
                        />
                        {getFieldError("address")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          placeholder="Apartment *"
                          {...formik.getFieldProps("apartment")}
                        />
                        {getFieldError("apartment")}
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          placeholder="City *"
                          {...formik.getFieldProps("city")}
                        />
                        {getFieldError("city")}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          placeholder="Phone *"
                          {...formik.getFieldProps("phone")}
                        />
                        {getFieldError("phone")}
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          type="email"
                          placeholder="Email address"
                          {...formik.getFieldProps("email")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <button
                        type="button"
                        className="submit submit-auto-width"
                        onClick={() => setShowAddressMap(true)}
                      >
                        Select Address From Map
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <button
                    type="button"
                    className="submit submit-auto-width"
                    onClick={() => setShowAddressMap(true)}
                  >
                    Select Address From Map
                  </button>
                )}
              </div>
            </div>
            <CheckOutOrderDetail />
            <Modal
              onDismiss={() => setShowAddressMap(false)}
              show={showAddressMap}
              contentStyle={{ width: "35%", minHeight: "70%", height: "70%" }}
            >
              <MapComponent2 onAddressChange={onMapAddressChange} />
            </Modal>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Checkout;
