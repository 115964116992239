import { IAxiosResponse, ICategory } from "src/models";
import axiosInstance from "./axios";

export default class MartCategoryService {
  private static readonly url: string = "/category";
  public static async findAll(): Promise<IAxiosResponse<ICategory[]>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }
}
