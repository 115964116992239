import { IAuthPayload, IOrder } from "src/models";
import * as Types from "src/redux/types";

type State = {
  orders: IOrder[];
  isLoaded: boolean;
};

const initialState: State = {
  orders: [],
  isLoaded: false,
};

const OrderReducer = (
  state = initialState,
  action: Action<IOrder[] | IOrder | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.ORDERS_LOAD: {
      const data = payload as IOrder[];
      return {
        ...state,
        orders: data,
        isLoaded: true,
      };
    }

    case Types.ORDERS_SAVE: {
      const data = payload as IOrder;
      return {
        ...state,
        orders: [...state.orders, data],
      };
    }

    case Types.ORDERS_DELETE: {
      const id = payload as number;
      return {
        ...state,
        orders: state.orders.filter((address) => address.id !== id),
      };
    }

    case Types.ORDERS_EDIT: {
      const data = payload as IOrder;
      return {
        ...state,
        orders: state.orders.map((address) =>
          address.id === data.id ? data : address
        ),
      };
    }

    case Types.ORDERS_RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default OrderReducer;
