/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import config from "src/config/config";
import Utils from "src/helper";
import { IProduct } from "src/models";
import { useMartCart, useMartWishlist, useProducts } from "src/redux/hooks";
import ProductInfo from "./components/ProductInfo";
import RelatedProduct from "./components/RelatedProduct";
import "./style.css";

const settings: Settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  //asNavFor: ".product-image-slider",
  dots: false,
  focusOnSelect: true,

  prevArrow: (
    <button type="button" className="slick-prev">
      <i className="fi-rs-arrow-small-left"></i>
    </button>
  ),
  nextArrow: (
    <button type="button" className="slick-next">
      <i className="fi-rs-arrow-small-right"></i>
    </button>
  ),
};

const productSliderSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  //asNavFor: ".slider-nav-thumbnails",
};

const ProductDetail = () => {
  const { id } = useParams();
  const { addCartItem } = useMartCart();
  const { findProductById } = useProducts();
  const [product, setProduct] = useState<IProduct>();
  const [quantity, setQuantity] = useState<string>("1");
  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      const data = await findProductById(parseInt("" + id));
      setProduct(data);
    })();
  }, [id]);
  const { isWishlisted, addWishlistItem, removeWishlistItem } =
    useMartWishlist();
  const isProductWishlisted = isWishlisted(parseInt("" + id));

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(parseInt("" + id));
    } else {
      if (product) {
        addWishlistItem(product);
      }
    }
  };

  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());
    }
  };

  const addToCart = () => {
    const q = Utils.getIntQuantity(quantity);
    addCartItem({
      product: product!,
      quantity: q,
    });
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(value);
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5"></i>Home
            </a>
            <span></span> <Link to="/shop">Shop</Link>
            <span></span> {product?.title} {product?.qtyPerUnit}
          </div>
        </div>
      </div>
      <div className="container mb-30">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="product-detail accordion-detail">
              <div className="row mb-50 mt-30">
                <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                  <div className="detail-gallery">
                    <span className="zoom-icon">
                      <i className="fi-rs-search"></i>
                    </span>
                    {/* MAIN SLIDES */}
                    <Slider
                      {...productSliderSettings}
                      className="product-image-slider"
                    >
                      {product?.images?.map((image, index) => (
                        <figure
                          className="border-radius-10 product-main-image-slider"
                          key={index}
                        >
                          <img
                            src={config.BASE_URL + image.path}
                            alt="product image"
                          />
                        </figure>
                      ))}
                    </Slider>
                    {/* THUMBNAILS */}
                    {/* {(product?.images || []).length > 0 && (
                      <Slider {...settings} className="slider-nav-thumbnails">
                        {product?.images?.map((image, index) => (
                          <div key={index}>
                            <img
                              src={config.BASE_URL + image.path}
                              alt="product image"
                            />
                          </div>
                        ))}
                      </Slider>
                    )} */}
                  </div>
                  {/* End Gallery */}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="detail-info pr-30 pl-30">
                    {(product?.discount || 0) > 0 && (
                      <span className="stock-status out-stock">
                        {Utils.roundTo1Digit(product?.discount || 0)}% Off
                      </span>
                    )}
                    <h2 className="title-detail">
                      {product?.title} {product?.qtyPerUnit}
                    </h2>
                    <div className="product-detail-rating">
                      <div className="product-rate-cover text-end">
                        <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                        <span className="font-small ml-5 text-muted">
                          {" "}
                          (32 reviews)
                        </span>
                      </div>
                    </div>
                    <div className="clearfix product-price-cover">
                      <div className="product-price primary-color float-left">
                        <span className="current-price text-brand">
                          Rs
                          {Utils.getDicountPrice(
                            product?.price || 0,
                            product?.discount || 0
                          )}
                        </span>
                        {(product?.discount || 0) > 0 && (
                          <span>
                            <span className="save-price  font-md color3 ml-15">
                              {Utils.roundTo1Digit(product?.discount || 0)}% Off
                            </span>
                            <span className="old-price font-md ml-15">
                              Rs
                              {product?.price}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                    {product?.description && product.description !== "" && (
                      <div className="short-desc mb-30">
                        <p className="font-lg">{product?.description}</p>
                      </div>
                    )}

                    <div className="detail-extralink mb-50">
                      <div className="detail-qty border radius">
                        <a onClick={decrementQuantity} className="qty-down">
                          <i className="fi-rs-angle-small-down"></i>
                        </a>
                        <span className="qty-val">
                          <input
                            type="text"
                            pattern="[0-9]*"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={quantity}
                            onChange={onQuantityChange}
                            className="quantity-input"
                          />
                        </span>
                        <a onClick={incrementQuantity} className="qty-up">
                          <i className="fi-rs-angle-small-up"></i>
                        </a>
                      </div>
                      <div className="product-extra-link2">
                        <button
                          type="button"
                          onClick={addToCart}
                          className="button button-add-to-cart"
                        >
                          <i className="fi-rs-shopping-cart"></i>Add to cart
                        </button>
                        <a
                          aria-label="Add To Wishlist"
                          className="action-btn hover-up"
                          onClick={addOrRemoveWishlist}
                        >
                          <i
                            className={
                              isProductWishlisted
                                ? "fa-solid fa-heart"
                                : "fi-rs-heart"
                            }
                          ></i>
                        </a>
                        <a
                          aria-label="Compare"
                          className="action-btn hover-up"
                          href="shop-compare.html"
                        >
                          <i className="fi-rs-shuffle"></i>
                        </a>
                      </div>
                    </div>
                    {/* <div className="font-xs">
                      <ul className="mr-50 float-start">
                        <li className="mb-5">
                          Type: <span className="text-brand">Organic</span>
                        </li>
                        <li className="mb-5">
                          MFG:<span className="text-brand"> Jun 4.2021</span>
                        </li>
                        <li>
                          LIFE: <span className="text-brand">70 days</span>
                        </li>
                      </ul>
                      <ul className="float-start">
                        <li className="mb-5">
                          SKU: <a href="#">FWM15VKT</a>
                        </li>
                        <li className="mb-5">
                          Tags:{" "}
                          <a href="#" rel="tag">
                            Snack
                          </a>
                          ,{" "}
                          <a href="#" rel="tag">
                            Organic
                          </a>
                          ,{" "}
                          <a href="#" rel="tag">
                            Brown
                          </a>{" "}
                        </li>
                        <li>
                          Stock:
                          <span className="in-stock text-brand ml-5">
                            8 Items In Stock
                          </span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {/* Detail Info */}
                </div>
              </div>
              {/* {<ProductInfo />} */}
              {product && (
                <RelatedProduct
                  productId={product.id}
                  productCategoryId={product.ProductCategoryId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductDetail;
