import React, { useEffect } from "react";
import { Breadcrumb } from "src/components";
import { useMartWishlist } from "src/redux/hooks";
import ItemWishlist from "./components/ItemWishlist";

const Wishlist = () => {
  const { products, size, findAll, isEmpty } = useMartWishlist();

  useEffect(() => {
    (async () => {
      if (!isEmpty) {
        await findAll();
      }
    })();
  }, []);
  return (
    <main className="main">
      <Breadcrumb pages={["Shop", "Wishlist"]} />

      <div className="container mb-30 mt-50">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="mb-50">
              <h1 className="heading-2 mb-10">Your Wishlist</h1>
              <h6 className="text-body">
                There are <span className="text-brand">{size}</span> products in
                this list
              </h6>
            </div>
            <div className="table-responsive shopping-summery">
              <table className="table table-wishlist">
                <thead>
                  <tr className="main-heading">
                    <th className="custome-checkbox start pl-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="exampleCheckbox11"
                        value=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox11"
                      ></label>
                    </th>
                    <th scope="col" colSpan={2}>
                      Product
                    </th>
                    <th scope="col">Price</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col">Action</th>
                    <th scope="col" className="end">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <ItemWishlist product={product} key={index} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Wishlist;
