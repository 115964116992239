/* eslint-disable radix */

import {
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberUtil,
} from "google-libphonenumber";
import { IOrder, IPhoneNumber, IProduct } from "../models";
const phoneUtils = PhoneNumberUtil.getInstance();

export default class Utils {
  public static getDeepNestedFieldValue = (path: string, obj: any) => {
    return path.split(".").reduce((p, c) => (p && p[c]) || null, obj);
  };

  public static isValidatePhoneNumber(phone: string | number): boolean {
    try {
      if (`${phone}`.length >= 10) {
        var number: PhoneNumber = phoneUtils.parse("" + phone, "PK");
        var valid = phoneUtils.isValidNumber(number);
        return valid;
      } else {
        return false;
      }
    } catch (e: any) {
      return false;
    }
  }

  private static getNumberType(numberType: PhoneNumberType): string {
    switch (numberType) {
      case PhoneNumberType.FIXED_LINE:
        return "Fixed Line";
      case PhoneNumberType.MOBILE:
        return "Mobile";
      case PhoneNumberType.FIXED_LINE_OR_MOBILE:
        return "Fixed Line/Mobile";
      case PhoneNumberType.TOLL_FREE:
        return "Toll Free";
      case PhoneNumberType.PREMIUM_RATE:
        return "Premium Rate";
      case PhoneNumberType.SHARED_COST:
        return "Shared Cost";
      case PhoneNumberType.VOIP:
        return "Voip";
      case PhoneNumberType.PERSONAL_NUMBER:
        return "Personal Number";
      case PhoneNumberType.PAGER:
        return "Pager";
      case PhoneNumberType.UAN:
        return "UAN";
      case PhoneNumberType.VOICEMAIL:
        return "Voicemail";
      case PhoneNumberType.UNKNOWN:
        return "Unknown";
      default:
        return "Unknown";
    }
  }

  public static getValidPhoneNumberWithCountryCode(
    phone: string | number
  ): IPhoneNumber {
    var number: PhoneNumber = phoneUtils.parse("" + phone, "PK");
    const numberType: PhoneNumberType = phoneUtils.getNumberType(number);
    return {
      fullNumber: parseInt(
        `${number.getCountryCode()}${number.getNationalNumber()}`
      ),
      regionCode: phoneUtils.getRegionCodeForNumber(number),
      countryCode: number.getCountryCode(),
      nationalNumber: number.getNationalNumber(),
      numberType: this.getNumberType(numberType),
      numberTypeCode: numberType,
    };
  }

  public static generateOTP() {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  public static getDicountPrice(price: number, discount: number) {
    return this.roundTo2Digit(price - this.calculateDiscount(price, discount));
  }

  public static calculateDiscount(price: number, discount: number) {
    return (price * discount) / 100;
  }

  public static roundTo2Digit(price: number) {
    return Math.round(price * 100) / 100;
  }

  public static roundTo1Digit(price?: number) {
    return price?.toFixed(2);
  }
  public static calculateOrderTotal(order: IOrder) {
    let total = 0.0;
    order.OrderItems.map((item) => {
      const { price, discount, quantity } = item;
      total += this.getDicountPrice(price, discount) * quantity;
    });

    return total;
  }

  public static getIntQuantity(quantity: string): number {
    const q = parseInt(quantity);
    if (isNaN(q)) return 1;
    if (q <= 0) return 1;

    return q;
  }

  public static getRandomProducts(
    sourceArray: IProduct[],
    neededElements: number
  ): IProduct[] {
    if (sourceArray.length === 0) {
      return [];
    }
    var result: IProduct[] = [];

    for (var i = 0; i < neededElements; i++) {
      result.push(sourceArray[Math.floor(Math.random() * sourceArray.length)]);
    }
    return result;
  }
}
