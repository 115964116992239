import { IAxiosResponse, IUser } from "src/models";
import axiosInstance from "./axios";

export default class UserService {
  public static readonly url: string = "/user";

  public static async updateProfileName(
    user: IUser
  ): Promise<IAxiosResponse<IUser>> {
    const response = await axiosInstance.post(`${this.url}/profile/edit`, user);
    return response.data;
  }
}
