import { IAuthPayload, IDeliveryCharge, IOrder } from "src/models";
import * as Types from "src/redux/types";

type State = {
  deliveryCharge: IDeliveryCharge;
  isLoaded: boolean;
};

const initialState: State = {
  deliveryCharge: { id: 1, minAmount: 0, charges: 0 },
  isLoaded: false,
};

const DeliveryChargesReducer = (
  state = initialState,
  action: Action<IDeliveryCharge>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.DELIVERY_CHARGES_LOAD: {
      const data = payload as IDeliveryCharge;
      return {
        ...state,
        deliveryCharge: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default DeliveryChargesReducer;
