import { IAxiosResponse, IState } from "src/models";
import axiosInstance from "./axios";

export default class StateService {
  private static readonly url: string = "/user-products-sale-stats";
  public static async getStates(): Promise<IAxiosResponse<IState[]>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }
}
