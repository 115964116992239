import React, { useEffect } from "react";

const JS = [
  "./../assets/js/vendor/modernizr-3.6.0.min.js",
  "./../assets/js/vendor/jquery-3.6.0.min.js",
  "./../assets/js/vendor/jquery-migrate-3.3.0.min.js",
  "./../assets/js/vendor/bootstrap.bundle.min.js",
  "./../assets/js/plugins/slick.js",
  "./../assets/js/plugins/jquery.syotimer.min.js",
  "./../assets/js/plugins/wow.js",
  "./../assets/js/plugins/jquery-ui.js",
  "./../assets/js/plugins/perfect-scrollbar.js",
  "./../assets/js/plugins/magnific-popup.js",
  "./../assets/js/plugins/select2.min.js",
  "./../assets/js/plugins/waypoints.js",
  "./../assets/js/plugins/counterup.js",
  "./../assets/js/plugins/jquery.countdown.min.js",
  "./../assets/js/plugins/images-loaded.js",
  "./../assets/js/plugins/isotope.js",
  "./../assets/js/plugins/scrollup.js",
  "./../assets/js/plugins/jquery.vticker-min.js",
  "./../assets/js/plugins/jquery.theia.sticky.js",
  "./../assets/js/plugins/jquery.elevatezoom.js",
  "./../assets/js/main.js",
  "./../assets/js/shop.js",
];
function AddLibrary(urlOfTheLibrary: string): HTMLScriptElement {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
  return script;
}

function AddAllJsLibrary(): HTMLScriptElement[] {
  const scripts: HTMLScriptElement[] = [];
  JS.forEach((js) => {
    const s = AddLibrary(js);
    scripts.push(s);
  });
  return scripts;
}

const useScript = () => {
  useEffect(() => {
    const scripts = AddAllJsLibrary();
    return () => {
      scripts.forEach((s) => document.body.removeChild(s));
    };
  }, []);
};

export default useScript;
