import React from "react";

type IProps = {
  isError?: boolean;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FormInput: React.FC<IProps> = ({
  isError,
  errorMessage,
  ...inputProps
}) => {
  return (
    <div className="form-group">
      <input {...inputProps} />
      {isError ? (
        <span className="error error12">{errorMessage}</span>
      ) : undefined}
    </div>
  );
};

export default FormInput;
