import { IProductCategory } from "src/models";
import * as Types from "src/redux/types";

type State = {
  productCategories: IProductCategory[];
  isLoaded: boolean;
};

const initialState: State = {
  productCategories: [],
  isLoaded: false,
};

const MartDiscountProductCategoryReducer = (
  state = initialState,
  action: Action<IProductCategory[]>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.MART_DISCOUNT_PRODUCT_CATEGORY_LOAD: {
      const data = payload as IProductCategory[];

      return {
        ...state,
        productCategories: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default MartDiscountProductCategoryReducer;
