import { IAxiosResponse, IOrder, IOrderItem } from "src/models";
import axiosInstance from "./axios";

export default class MartOrderService {
  private static readonly url: string = "/user/orders";

  public static async save(order: IOrder): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.post(this.url, order);
    return response.data;
  }
  public static async findAll(): Promise<IAxiosResponse<IOrder[]>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }

  public static async editOrderItem(data: {
    order: IOrder;
    orderItem: IOrderItem;
  }): Promise<IAxiosResponse<IOrder[]>> {
    const response = await axiosInstance.put(`${this.url}/item/edit`, data);
    return response.data;
  }

  public static async findById(id: number): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.get(`${this.url}/${id}`);
    return response.data;
  }
  public static async removeOrderItem(data: {
    order: IOrder;
    orderItem: IOrderItem;
  }): Promise<IAxiosResponse<IOrder[]>> {
    const response = await axiosInstance.put(`${this.url}/item/remove`, data);
    return response.data;
  }
  public static async cancelOrder(
    order: IOrder
  ): Promise<IAxiosResponse<IOrder[]>> {
    const response = await axiosInstance.put(`${this.url}/cancel`, order);
    return response.data;
  }
}
