import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}
const TextInput: React.FC<IProps> = ({ label, error, ...inputProps }) => {
  return (
    <>
      <label>
        {label} {inputProps.required && <span className="required">*</span>}
      </label>
      <input className="form-control" type="text" {...inputProps} />
      {error && <span className="error">{error}</span>}
    </>
  );
};

export default TextInput;
