import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "src/components";

const MemebersPage = () => {
  return (
    <main className="main pages">
      <Breadcrumb pages={["About Us"]} />
      <div className="page-content pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="mb-50">
                <h2 className="title style-3 mb-40 text-center">Our Members</h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <div className="team-card">
                          <img src="/assets/imgs/page/ansar.jpg" alt="" />
                          <div className="content text-center">
                            <h4 className="mb-5">Muhammad Ansar Ahsan</h4>
                            <span>Project Manager</span>
                            <div className="social-network mt-20">
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-facebook-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-twitter-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-instagram-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-youtube-brand.svg"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-4 col-md-4">
                        <div className="team-card">
                          <img src="/assets/imgs/page/amir.jpg" alt="" />
                          <div className="content text-center">
                            <h4 className="mb-5">Muhammad Amir</h4>
                            <span>Lead Developer</span>
                            <div className="social-network mt-20">
                              <a href="https://www.facebook.com/profile.php?id=100059957883363">
                                <img
                                  src="assets/imgs/theme/icons/icon-facebook-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-twitter-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-instagram-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-youtube-brand.svg"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-4 col-md-4">
                        <div className="team-card">
                          <img src="/assets/imgs/page/uzair.jpg" alt="" />
                          <div className="content text-center">
                            <h4 className="mb-5">Uzair Ahmad</h4>
                            <span>UI/UX Designer</span>
                            <div className="social-network mt-20">
                              <a href="https://www.facebook.com/profile.php?id=100015879399143">
                                <img
                                  src="assets/imgs/theme/icons/icon-facebook-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-twitter-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="https://www.linkedin.com/in/uzair-ahmad-b1aa58120/">
                                <img
                                  src="assets/imgs/theme/icons/icon-instagram-brand.svg"
                                  alt=""
                                />
                              </a>
                              <a href="#">
                                <img
                                  src="assets/imgs/theme/icons/icon-youtube-brand.svg"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MemebersPage;
