import { IAxiosResponse, IStoreQrDiscount } from "src/models";
import axiosInstance from "./axios";

export default class StoreService {
  private static readonly url: string = "/storeqrdiscount";
  public static async findAll(): Promise<IAxiosResponse<IStoreQrDiscount>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }
}
