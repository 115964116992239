import React from "react";
import { Link, LinkProps } from "react-router-dom";
import config from "src/config/config";
import { ICategory } from "src/models";

interface IProps {
  category: ICategory;
  isActive?: boolean;
}

const CategoryItem: React.FC<IProps> = ({ category, isActive }) => {
  const { title, id, image } = category;
  return (
    <li className={isActive ? "active-category" : ""}>
      <Link to={`/shop?category=${id}`}>
        <img src={config.BASE_URL + image.path} alt="" />
        <span>{title}</span>
      </Link>
    </li>
  );
};

export default CategoryItem;
