import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "..";
import { StoreQrDiscountActions } from "../actions";
import { StoreService } from "../services";

const useStore = () => {
  const dispatch = useDispatch();
  const {
    martWebsiteAuth: { isLoggedIn, user },
    storeQrDiscount: { storeQrDiscount, isLoaded },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const findAll = async () => {
    try {
      setLoading(true);
      const response = await StoreService.findAll();
      if (response.response) {
        dispatch(StoreQrDiscountActions.load(response.response));
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    isLoggedIn,
    user,
    isLoaded,
    storeQrDiscount,
    findAll,
  };
};

export default useStore;
