import React, { Fragment, useEffect } from "react";
import { HelmetScript } from "../hooks";

const Main: React.FC = ({ children }) => {
  return (
    <Fragment>
      {children}
      <HelmetScript />
    </Fragment>
  );
};

export default Main;
