/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMartOrders } from "src/redux/hooks";
import moment from "moment";
import { Link } from "react-router-dom";
import { IOrder } from "src/models";
import { Modal, OrderTrackingDialog } from "src/components";

const Orders = () => {
  const { isLoggedIn, findAll, orders, isLoaded } = useMartOrders();
  const [currentOrder, setCurrentOrder] = useState<IOrder>();
  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);

  return (
    <div
      className="tab-pane fade"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="mb-0">Your Orders</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Actions</th>
                  <th>Track</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td>#{order.id}</td>
                    <td>{moment(order.createdAt).format("MMMM DD , YYYY")}</td>
                    <td>
                      <span
                        className={`order-status status-${order.orderStatus.toLowerCase()}`}
                      >
                        {order.orderStatus}
                      </span>
                    </td>
                    <td>
                      Rs {order.total} for {order.OrderItems.length} item
                    </td>
                    <td>
                      <Link
                        to={`/invoice/${order.id}`}
                        className="btn-small d-block"
                      >
                        <i className="fas fa-print"></i> View
                      </Link>
                    </td>
                    <td>
                      <a
                        onClick={() => setCurrentOrder(order)}
                        className="btn-small d-block"
                      >
                        <i className="fas fa-print"></i> View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={currentOrder !== undefined}
        onDismiss={() => setCurrentOrder(undefined)}
        contentStyle={{ width: "20%", minHeight: "50%" }}
      >
        <OrderTrackingDialog
          status={currentOrder?.orderStatus!}
          declinedReason={currentOrder?.declinedReason}
        />
      </Modal>
    </div>
  );
};

export default Orders;
