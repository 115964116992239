/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IAdmin } from "src/models";
import * as Types from "src/redux/types";

export default class ShopActions {
  public static load =
    (admins?: IAdmin[]) =>
    async (
      dispatch: DispatchType<Action<IAdmin[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.SHOPS_LOAD,
        payload: admins,
      });
}
