/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IProduct } from "src/models";
import { useProducts } from "src/redux/hooks";
import QuickView from "../../product-detail/components/QuickView";
import ItemProduct from "../../shop/components/ItemProduct";

const PopularProducts = () => {
  const [quickViewProduct, setQuickViewProduct] = useState<IProduct>();
  const {
    findDiscountProductsWithCategory,
    isProductCategoryLoaded,
    productCategories,
  } = useProducts();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (!isProductCategoryLoaded) {
        await findDiscountProductsWithCategory();
      }
    })();
  }, [isProductCategoryLoaded]);

  const onQuickViewClick = (product: IProduct) => {
    setQuickViewProduct(product);
  };
  return (
    <section className="product-tabs section-padding position-relative wow fadeIn animated">
      <div className="container">
        <div className="section-title style-2">
          <h3>Discount Products</h3>
          <ul className="nav nav-tabs links" id="myTab" role="tablist">
            {productCategories.map((category, index) => (
              <li className="nav-item" role="presentation" key={category.id}>
                <button
                  className={`nav-link ${
                    activeTabIndex === index ? "active" : ""
                  }`}
                  id={`"nav-tab-${category.id}}"`}
                  data-bs-toggle="tab"
                  data-bs-target={`#tab-${category.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`tab-${category.id}`}
                  aria-selected="true"
                  onClick={() => setActiveTabIndex(index)}
                >
                  {category.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/*End nav-tabs*/}
        <div className="tab-content wow fadeIn animated" id="myTabContent">
          {productCategories.map((category, index) => (
            <div
              key={category.id}
              className={`tab-pane ${
                activeTabIndex === index ? "fade show active" : ""
              }`}
              id={`tab-${category.id}`}
              role="tabpanel"
              aria-labelledby={`tab-${category.id}`}
            >
              <div className="row product-grid-4">
                {category.Products.map((product, index) => (
                  <ItemProduct
                    product={product}
                    key={product.id}
                    onQuickViewClick={onQuickViewClick}
                  />
                ))}
                {/*end product card*/}
              </div>
              {/*End product-grid-4*/}
            </div>
          ))}
          {/*En tab one*/}
        </div>
        {/*End tab-content*/}
      </div>
      <QuickView
        show={quickViewProduct !== undefined}
        onDismiss={() => setQuickViewProduct(undefined)}
        product={quickViewProduct}
      />
    </section>
  );
};

export default PopularProducts;
