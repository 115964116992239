import { IAxiosResponse, IContactUs } from "src/models";
import axiosInstance from "./axios";

export default class ContactUsService {
  private static readonly url: string = "/contact-us";
  public static async sendMessage(
    contact: IContactUs
  ): Promise<IAxiosResponse<string>> {
    const response = await axiosInstance.post(this.url, contact);
    return response.data;
  }
}
