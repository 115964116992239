import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import AddressReducer from "./AddressReducer";
import OrderReducer from "./OrderReducer";
import ProductPaginationReducer from "./ProductPaginatinReducer";
import MartCartReducer from "./MartCartReducer";
import MartWishlistReducer from "./MartWishlistReducer";
import DeliveryChargesReducer from "./DeliveryChargesReducer";
import MartDiscountProductCategoryReducer from "./MartDiscountProductCategoryReducer";
import MartCategoriesReducer from "./MartCategoriesReducer";
import StoreQrDiscountReducer from "./StoreQrDiscountReducer";
import ShopsReducer from "./ShopsReducers";
import MartDiscountProductReducer from "./MartDiscountProductReducer";
import MartBestSellsProductReducer from "./MartBestSellsProductReducer";

export default combineReducers({
  martWebsiteAuth: AuthReducer,
  martWebsiteAddress: AddressReducer,
  martWebsiteOrders: OrderReducer,
  martProductPagination: ProductPaginationReducer,
  martCart: MartCartReducer,
  martWishlist: MartWishlistReducer,
  deliveryCharges: DeliveryChargesReducer,
  martDiscountProductCategory: MartDiscountProductCategoryReducer,
  martCategories: MartCategoriesReducer,
  storeQrDiscount: StoreQrDiscountReducer,
  shops: ShopsReducer,
  martDiscountProducts: MartDiscountProductReducer,
  martBestSellsProducts: MartBestSellsProductReducer,
});
