import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useMartCart, useMartWishlist } from "src/redux/hooks";
import { useAppSelector } from "../../redux";
import CategoryItem from "./../category-item";
import HeaderCategory from "./components/HeaderCategory";
import ItemCartMobile from "./components/ItemCartMobile";
import MegaMenu from "./components/MegaMenu";
import MiddleHeader from "./components/MiddleHeader";

const Header = () => {
  const { isLoggedIn } = useAppSelector((state) => state.martWebsiteAuth);
  const martCart = useMartCart();
  const martWishlist = useMartWishlist();

  return (
    <header className="header-area header-style-1 header-height-2">
      {/* <div className="mobile-promotion">
        <span>
          Grand opening, <strong>up to 15%</strong> off all items. Only{" "}
          <strong>3 days</strong> left
        </span>
      </div> */}
      <MiddleHeader />
      <div className="header-bottom header-bottom-bg-color sticky-bar">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            <div className="logo logo-width-1 d-block d-lg-none">
              <a href="index.html">
                <img src="/assets/imgs/theme/logo512.png" alt="logo" />
              </a>
            </div>
            <div className="header-nav d-none d-lg-flex">
              <HeaderCategory />
              <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                <nav>
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="shop">Shop</NavLink>
                    </li>
                    {/* <MegaMenu /> */}

                    {isLoggedIn ? (
                      <li>
                        <NavLink to="/my-account">My Account</NavLink>
                      </li>
                    ) : (
                      <li>
                        <NavLink to="/login">Login</NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink to="contact-us">Contact</NavLink>
                    </li>
                    <li>
                      <a
                        href="https://najeebstore-api.resourceinn.com/recruitment/jobPost/1"
                        target="_blank"
                      >
                        Job Opportunity
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="hotline d-none d-lg-flex">
              <img
                src="/assets/imgs/theme/icons/icon-headphone.svg"
                alt="hotline"
              />
              <p>
                051-2107676<span>24/7 Support Center</span>
              </p>
            </div>
            <div className="header-action-icon-2 d-block d-lg-none">
              <div className="burger-icon burger-icon-white">
                <span className="burger-icon-top"></span>
                <span className="burger-icon-mid"></span>
                <span className="burger-icon-bottom"></span>
              </div>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <Link to="/wishlist">
                    <img
                      alt="Nest"
                      src="/assets/imgs/theme/icons/icon-heart.svg"
                    />
                    <span className="pro-count white">{martWishlist.size}</span>
                  </Link>
                </div>
                <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to="/cart">
                    <img
                      alt="Nest"
                      src="/assets/imgs/theme/icons/icon-cart.svg"
                    />
                    <span className="pro-count white">{martCart.size}</span>
                  </Link>
                  <div className="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                      {!martCart.isEmpty ? (
                        martCart.items.map((item, index) => (
                          <ItemCartMobile cartItem={item} key={index} />
                        ))
                      ) : (
                        <li>
                          <h6 className="text-center">
                            Look like you haven't add any item in your cart.
                          </h6>
                        </li>
                      )}
                    </ul>
                    <div className="shopping-cart-footer">
                      <div className="shopping-cart-total">
                        <h4>
                          Total <span>Rs{martCart.total}</span>
                        </h4>
                      </div>
                      <div className="shopping-cart-button">
                        <Link to="/cart">View cart</Link>
                        {!martCart.isEmpty && (
                          <Link to="/checkout">Checkout</Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
