/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Modal } from "src/components";
import Slider, { Settings } from "react-slick";
import { IOrder, IOrderItem } from "src/models";
import config from "src/config/config";
import { Link } from "react-router-dom";
import Utils from "src/helper";
import { useDeliveryCharges, useMartOrders } from "src/redux/hooks";

const productSliderSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  //asNavFor: ".slider-nav-thumbnails",
};

interface IProps {
  show?: boolean;
  onDismiss?: () => void;
  orderItem?: IOrderItem;
  order: IOrder;
  afterOrderChanged?: (order: IOrder) => void;
}

const EditOrderItemDialog: React.FC<IProps> = ({
  show,
  onDismiss,
  orderItem,
  order,
  afterOrderChanged,
}) => {
  const { editOrderItem, loading } = useMartOrders();
  const { deliveryCharge } = useDeliveryCharges();
  const [quantity, setQuantity] = useState<string>("0");

  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());
    }
  };

  useEffect(() => {
    if (orderItem) {
      setQuantity(orderItem.quantity.toString());
    }

    return () => {
      setQuantity("0");
    };
  }, [orderItem]);

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(value);
  };

  const getQuantity = () => {
    const q = parseInt(quantity);
    if (isNaN(q)) return 0;
    return q;
  };

  const onUpdate = async () => {
    const qty = getQuantity();
    if (orderItem !== undefined && qty !== orderItem.quantity) {
      orderItem.quantity = qty > 0 ? qty : 1;
      let orderItems = order.OrderItems.map((item) =>
        item.id === orderItem.id ? orderItem : item
      );
      order.OrderItems = orderItems;
      const total = Utils.calculateOrderTotal(order);
      order.total = total;
      order.subTotal = total;
      order.grandTotal = total;
      order.deliveryCharges =
        total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;

      await editOrderItem({ order: order, orderItem: orderItem }, () => {
        afterOrderChanged?.(order);
      });
    } else {
      alert("no update");
    }
  };

  return (
    <Modal show={show} onDismiss={onDismiss}>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
          <div className="detail-gallery">
            <span className="zoom-icon">
              <i className="fi-rs-search"></i>
            </span>

            <Slider {...productSliderSettings} className="product-image-slider">
              {orderItem?.Product?.images?.map((image, index) => (
                <figure
                  className="border-radius-10 product-main-image-slider"
                  key={index}
                >
                  <img src={config.BASE_URL + image.path} alt="product image" />
                </figure>
              ))}
            </Slider>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="detail-info pr-30 pl-30">
            {(orderItem?.Product?.discount || 0) > 0 && (
              <span className="stock-status out-stock">
                {Utils.roundTo1Digit(orderItem?.Product?.discount)}% off
              </span>
            )}
            <h3 className="title-detail">
              <Link
                to={`/shop/product-detail/${orderItem?.Product?.id}`}
                className="text-heading"
              >
                {orderItem?.Product?.title} {orderItem?.Product?.qtyPerUnit}
              </Link>
            </h3>
            <div className="product-detail-rating">
              <div className="product-rate-cover text-end">
                <div className="product-rate d-inline-block">
                  <div
                    className="product-rating"
                    style={{ width: "90%" }}
                  ></div>
                </div>
                <span className="font-small ml-5 text-muted">
                  {" "}
                  (32 reviews)
                </span>
              </div>
            </div>
            <div className="clearfix product-price-cover">
              <div className="product-price primary-color float-left">
                <span className="current-price text-brand">
                  Rs
                  {Utils.getDicountPrice(
                    orderItem?.Product?.price || 0,
                    orderItem?.Product?.discount || 0
                  )}
                </span>
                {(orderItem?.Product?.discount || 0) > 0 && (
                  <span>
                    <span className="save-price  font-md color3 ml-15">
                      {Utils.roundTo1Digit(orderItem?.Product?.discount)}% Off
                    </span>
                    <span className="old-price font-md ml-15">
                      Rs{orderItem?.Product?.price}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <div className="detail-extralink mb-30">
              <div className="detail-qty border radius">
                <a onClick={decrementQuantity} className="qty-down">
                  <i className="fi-rs-angle-small-down"></i>
                </a>
                <span className="qty-val">
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={quantity}
                    onChange={onQuantityChange}
                    className="quantity-input"
                  />
                </span>
                <a onClick={incrementQuantity} className="qty-up">
                  <i className="fi-rs-angle-small-up"></i>
                </a>
              </div>
              <div className="product-extra-link2">
                <button
                  className="button button-add-to-cart"
                  disabled={loading}
                  onClick={onUpdate}
                >
                  <i className="fi-rs-shopping-cart"></i>Update Item
                </button>
              </div>
            </div>
            <div className="font-xs">
              <ul>
                <li className="old-price font-md ml-15">
                  Total:{" "}
                  <span className="text-brand">
                    Rs{" "}
                    {Utils.getDicountPrice(
                      orderItem?.Product?.price || 0,
                      orderItem?.Product?.discount || 0
                    ) * getQuantity()}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditOrderItemDialog;
