import React from "react";
import Slider, { Settings } from "react-slick";
import { SubscribeEmail } from "src/components";
import { HomeHeaderSliderData } from "src/data";
import "./css/homeslider.css";

const HomeSlider = () => {
  const settings: Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    infinite: true,
    pauseOnHover: true,
    prevArrow: (
      <span className="slider-btn slider-prev">
        <i className="fi-rs-angle-left"></i>
      </span>
    ),
    nextArrow: (
      <span className="slider-btn slider-next">
        <i className="fi-rs-angle-right"></i>
      </span>
    ),
    // appendArrows: ".hero-slider-1-arrow",
    autoplay: true,
  };
  return (
    <section className="home-slider position-relative mb-30">
      <div className="container">
        <div className="home-slide-cover mt-30">
          <Slider
            {...settings}
            className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1"
          >
            {HomeHeaderSliderData.map((slider, index) => (
              <div key={index}>
                <div
                  className="single-hero-slider "
                  style={{
                    backgroundImage: `url(${slider.imageUrl})`,
                  }}
                >
                  <div className="slider-content">
                    <h1 className="display-2 mb-40">{slider.title()}</h1>
                    <p className="mb-65">{slider.description}</p>
                    <SubscribeEmail />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeSlider;
