import React, { useEffect } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minHeight: "40%",
    borderRadius: 20,
    borderColor: "#ee1c25",
  },
};

interface IProps {
  show?: boolean;
  contentStyle?: any;
  onDismiss?: () => void;
}

Modal.setAppElement("#root");

const CustomModal: React.FC<IProps> = ({
  show,
  onDismiss,
  children,
  contentStyle,
}) => {
  // useEffect(() => {
  //   if (show) {
  //     document.body.classList.add("modal-open");
  //   } else {
  //     document.body.classList.remove("modal-open");
  //   }
  // }, [show]);

  const className = ["modal fade custom-modal"]
    .filter((c) => c !== undefined)
    .join(" ");

  const style = {
    ...customStyles,
    content: {
      ...customStyles.content,
      ...contentStyle,
    },
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onDismiss}
      style={style}
      aria-hidden="true"
    >
      {children}
    </Modal>
    // <div
    //   className={className}
    //   id="quickViewModal"
    //   tabIndex={-1}
    //   aria-labelledby="quickViewModalLabel"
    //   aria-hidden="true"
    //   style={style}
    // >
    //   <div className="modal-dialog">
    //     <div className="modal-content">
    //       <button
    //         type="button"
    //         className="btn-close"
    //         data-bs-dismiss="modal"
    //         aria-label="Close"
    //         onClick={onDismiss}
    //       ></button>
    // <div className="modal-body">
    //   <div className="row">
    //     <p>body</p>
    //   </div>
    // </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CustomModal;
