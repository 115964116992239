import React, { useEffect, useState } from "react";
import { useShops } from "src/redux/hooks";
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { IAdmin } from "src/models";
const key = "AIzaSyBUQd4IMK4AWYpWZvlLqnUiy_grOFO1jy8";

const ContactMap = () => {
  const { shops } = useShops();
  const [center, setCenter] = useState({ lat: 33.6844, lng: 73.0479 });
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [currentShop, setCurrentShop] = useState<IAdmin>();

  const onMapLoad = (map: google.maps.Map) => {
    getCurrentLocationUpdates();

    google.maps.event.addListener(map, "bounds_changed", () => {
      const bound = map.getBounds();
      if (bound) setBounds(bound);
    });
  };

  const getCurrentLocationUpdates = () => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCenter(pos);
      }
    );
  };

  useEffect(() => {
    if (shops.length !== 0) {
      setCenter({ lat: shops[0].latitude, lng: shops[0].longitude });
    }
  }, [shops]);

  return (
    <LoadScript googleMapsApiKey={key}>
      <GoogleMap
        center={center}
        zoom={13}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
      >
        {shops.map((shop) => (
          <Marker
            position={{ lat: shop.latitude, lng: shop.longitude }}
            onClick={() => setCurrentShop(shop)}
          />
        ))}
        {currentShop && (
          <InfoWindow
            position={{ lat: currentShop.latitude, lng: currentShop.longitude }}
            onCloseClick={() => setCurrentShop(undefined)}
          >
            <div>
              <h5>
                <b>{currentShop?.storeName}</b>
              </h5>
              <p>
                <strong>Address : </strong>
                {currentShop?.address}
              </p>
              <p>
                <strong>Phone : </strong>
                {currentShop?.phone}
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default ContactMap;
