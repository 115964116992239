import { useFormik } from "formik";
import React from "react";
import { IContactUs } from "src/models";
import { useAuth, useContactUs } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";

const ContactUsForm = () => {
  const { user } = useAuth();
  const { loading, sendMessage } = useContactUs();
  const initialValues: IContactUs = {
    name: user?.name || "",
    email: "",
    phone: user?.phone || "",
    subject: "",
    message: "",
    isRead: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is required")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      swal({
        title: `Are you sure to send us a message?`,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willSave) => {
        if (willSave) {
          await sendMessage(
            values,
            () => {
              swal(
                `Success! Your message has been sent successfully. We will contact you shortly!`,
                {
                  icon: "success",
                }
              );
            },
            (error: string) => {
              swal(`OOPS! ${error}!`, {
                icon: "error",
              });
            }
          );
        }
      });
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const getFieldError = (fieldName: string) => {
    const hasError = errors[fieldName];
    const hastouched = touched[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };
  return (
    <form
      className="contact-form-style mt-30"
      id="contact-form"
      onSubmit={handleSubmit}
      noValidate
    >
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="input-style mb-20">
            <input
              placeholder="First Name"
              type="text"
              {...getFieldProps("name")}
            />
            {getFieldError("name")}
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="input-style mb-20">
            <input
              placeholder="Your Email"
              type="email"
              {...getFieldProps("email")}
            />
            {getFieldError("email")}
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="input-style mb-20">
            <input
              placeholder="Your Phone"
              type="tel"
              {...getFieldProps("phone")}
            />
            {getFieldError("phone")}
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="input-style mb-20">
            <input
              placeholder="Subject"
              type="text"
              {...getFieldProps("subject")}
            />
            {getFieldError("subject")}
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="textarea-style mb-30">
            <textarea
              placeholder="Message"
              {...getFieldProps("message")}
            ></textarea>
            {getFieldError("message")}
          </div>
          <button className="submit submit-auto-width" type="submit">
            Send message
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactUsForm;
