/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDeliveryCharges, useMartOrders } from "src/redux/hooks";
import Page404 from "../page404/Page404";
import moment from "moment";
import Utils from "src/helper";
import { Modal } from "src/components";
import EditOrderItemDialog from "./components/EditOrderItemDialog";
import { IOrder, IOrderItem } from "src/models";
import swal from "sweetalert";

const Invoice2 = () => {
  const { id } = useParams();
  const { findById, loading, removeOrderItem, cancelOrder } = useMartOrders();
  const { deliveryCharge } = useDeliveryCharges();
  const [order, setOrder] = useState<IOrder>();

  const isEditable =
    order?.orderStatus === "New" || order?.orderStatus === "Pending";
  const [editOrderItem, setEditOrderItem] = useState<IOrderItem>();

  const colSpan = isEditable ? 4 : 3;

  const onEditOrderItemDismiss = () => {
    setEditOrderItem(undefined);
  };

  useEffect(() => {
    (async () => {
      const o = await findById(parseInt("" + id));
      setOrder(o);
    })();

    return () => {
      setOrder(undefined);
    };
  }, [id]);

  const afterOrderChanged = (order: IOrder) => {
    setOrder(order);
  };

  const confirmToRemoveOrderItem = (orderItem: IOrderItem) => {
    swal({
      title: `Are you sure to delete "${orderItem.Product?.title}"?`,
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete && order) {
        let orderItems = order.OrderItems.filter(
          (item) => item.id !== orderItem.id
        );
        order.OrderItems = orderItems;
        const total = Utils.calculateOrderTotal(order);
        order.total = total;
        order.subTotal = total;
        order.grandTotal = total;
        order.deliveryCharges =
          total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;
        console.log(order, orderItem);
        if (orderItems.length > 0) {
          await removeOrderItem(
            {
              orderItem: orderItem,
              order: order,
            },
            () => {
              swal(
                `Success! Your "${orderItem.Product?.title}" has been deleted!`,
                {
                  icon: "success",
                }
              );
            }
          );
        } else {
          await cancelOrder(order, () => {
            swal(`Success! Your order has been canceled successfully`, {
              icon: "success",
            });
          });
        }
      }
    });
  };

  return loading ? (
    <div className="invoice invoice-content invoice-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="invoice-inner">
              <div className="invoice-info" id="invoice_wrapper">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="preloader-inner position-relative">
                    <div className="text-center">
                      <img src="/assets/imgs/theme/loading.gif" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : order !== undefined ? (
    <>
      <div className="invoice invoice-content invoice-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="invoice-inner">
                <div className="invoice-info" id="invoice_wrapper">
                  <div className="invoice-header">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="invoice-numb">
                          <h4 className="invoice-header-1 mb-10 mt-20">
                            Invoice No:{" "}
                            <span className="text-brand">#{order?.id}</span>{" "}
                            <span
                              className={`order-status status-${order?.orderStatus.toLowerCase()}`}
                            >
                              {order?.orderStatus}
                            </span>
                          </h4>
                          <h6 className="">
                            Date:{" "}
                            {moment(order?.createdAt).format("DD MMM YYYY")}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-name text-end">
                          <div className="logo">
                            <Link to="/">
                              <img
                                src="/assets/imgs/theme/logo512.png"
                                alt="logo"
                                className="invoice-logo"
                              />
                            </Link>
                            <p className="text-sm text-mutted">
                              {order?.Admin?.address} <br />{" "}
                              {order?.Admin?.city}, , Pakistan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-top">
                    <div className="row">
                      <div className="col-lg-9 col-md-6">
                        <div className="invoice-number">
                          <h4 className="invoice-title-1 mb-10">Invoice To</h4>
                          <p className="invoice-addr-1">
                            <strong>{order?.name}</strong> <br />
                            <strong>Phone : {order?.phone}</strong> <br />
                            {order?.address}, <br />
                            {order?.city}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                      <div className="invoice-number">
                        <h4 className="invoice-title-1 mb-10">Bill To</h4>
                        <p className="invoice-addr-1">
                          <strong>NestMart Inc</strong> <br />
                          <a
                            href="/cdn-cgi/l/email-protection"
                            className="__cf_email__"
                            data-cfemail="37555e5b5b5e595077795244437a5645431954585a"
                          >
                            [email&#160;protected]
                          </a>{" "}
                          <br />
                          205 North Michigan Avenue, <br />
                          Suite 810 Chicago, 60601, USA
                        </p>
                      </div>
                    </div> */}
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-9 col-md-6">
                        <h4 className="invoice-title-1 mb-10">Due Date:</h4>
                        <p className="invoice-from-1">
                          {moment(order?.createdAt).format("DD MMMM YYYY")}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <h4 className="invoice-title-1 mb-10">
                          Payment Method
                        </h4>
                        <p className="invoice-from-1">Cash on delivery</p>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-center">
                    <div className="table-responsive">
                      <table className="table table-striped invoice-table">
                        <thead className="bg-active">
                          <tr>
                            {isEditable && <th>Action</th>}
                            <th>Item Item</th>
                            <th className="text-center">Unit Price</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.OrderItems.map((item) => (
                            <tr key={item.id}>
                              {isEditable && (
                                <td>
                                  <a onClick={() => setEditOrderItem(item)}>
                                    <i className="fas fa-edit"></i> Edit
                                  </a>{" "}
                                  |{" "}
                                  <a
                                    onClick={() =>
                                      confirmToRemoveOrderItem(item)
                                    }
                                  >
                                    <i className="fas fa-trash"></i> Delete
                                  </a>
                                </td>
                              )}
                              <td>
                                <div className="item-desc-1">
                                  <span>
                                    {item.Product?.title}{" "}
                                    {item.Product?.qtyPerUnit}
                                  </span>{" "}
                                  {item.discount > 0 && (
                                    <small>
                                      (%{Utils.roundTo1Digit(item.discount)}{" "}
                                      off)
                                    </small>
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                Rs{" "}
                                {Utils.getDicountPrice(
                                  item.price,
                                  item.discount
                                )}{" "}
                                {item.discount > 0 && (
                                  <small className="line-through">
                                    {item.price}
                                  </small>
                                )}
                              </td>
                              <td className="text-center">{item.quantity}</td>
                              <td className="text-right">
                                Rs {item.quantity * item.price}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={colSpan} className="text-end f-w-600">
                              SubTotal
                            </td>
                            <td className="text-right">Rs {order?.subTotal}</td>
                          </tr>
                          <tr>
                            <td colSpan={colSpan} className="text-end f-w-600">
                              Tax
                            </td>
                            <td className="text-right">FREE</td>
                          </tr>
                          <tr>
                            <td colSpan={colSpan} className="text-end f-w-600">
                              Delivery Charges
                            </td>
                            <td className="text-right">
                              {(order?.deliveryCharges || 0) > 0
                                ? `Rs ${order?.deliveryCharges}`
                                : "FREE"}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={colSpan} className="text-end f-w-600">
                              Grand Total
                            </td>
                            <td className="text-right f-w-600">
                              Rs {order?.total + order?.deliveryCharges}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="invoice-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <h3 className="invoice-title-1">Important Note</h3>
                          <ul className="important-notes-list-1">
                            <li>
                              All amounts shown on this invoice are in pakistani
                              ruppes
                            </li>
                            {/* <li>
                              finance charge of 1.5% will be made on unpaid
                              balances after 30 days.
                            </li> */}
                            {/* <li>Once order done, money can't refund</li> */}
                            <li>
                              Delivery might delay due to some external
                              dependency
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6 col-offsite">
                        <div className="text-end">
                          <p className="mb-0 text-13">
                            Thank you for your order
                          </p>
                          <p>
                            <strong>{order?.User?.name}</strong>
                          </p>
                          {/* <div className="mobile-social-icon mt-50 print-hide">
                          <h6>Follow Us</h6>
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-facebook-white.svg"
                              alt=""
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-twitter-white.svg"
                              alt=""
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-instagram-white.svg"
                              alt=""
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-pinterest-white.svg"
                              alt=""
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-youtube-white.svg"
                              alt=""
                            />
                          </a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="invoice-btn-section clearfix d-print-none">
                <a
                  href="javascript:window.print()"
                  className="btn btn-lg btn-custom btn-print hover-up"
                >
                  {" "}
                  <img
                    src="assets/imgs/theme/icons/icon-print.svg"
                    alt=""
                  />{" "}
                  Print{" "}
                </a>
                <a
                  id="invoice_download_btn"
                  className="btn btn-lg btn-custom btn-download hover-up"
                >
                  {" "}
                  <img
                    src="assets/imgs/theme/icons/icon-download.svg"
                    alt=""
                  />{" "}
                  Download{" "}
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditOrderItemDialog
        show={editOrderItem !== undefined}
        onDismiss={onEditOrderItemDismiss}
        orderItem={editOrderItem}
        order={order!}
        afterOrderChanged={afterOrderChanged}
      />
    </>
  ) : (
    <Page404 />
  );
};

export default Invoice2;
