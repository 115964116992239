/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Footer, Header, HeaderMobile, Preloader } from "./components";
import { Main, RequireAuth } from "./container";
import {
  About,
  Cart,
  Checkout,
  Compare,
  ContactUs,
  Home,
  Invoice2,
  Login,
  MemebersPage,
  MyAccount,
  Page404,
  PrivacyPolicy,
  ProductDetail,
  Register,
  Shop2,
  Wishlist,
} from "./pages";
import { Routes, Route } from "react-router-dom";
import { useDeliveryCharges, useStore } from "./redux/hooks";

const App = () => {
  const deliveryCharge = useDeliveryCharges();
  const store = useStore();
  useEffect(() => {
    (async () => {
      if (!deliveryCharge.isLoaded) {
        await deliveryCharge.getDeliveryCharges();
      }
    })();
  }, [deliveryCharge.isLoaded]);

  useEffect(() => {
    (async () => {
      await store.findAll();
    })();
  }, []);
  return (
    <Main>
      <Header />
      <HeaderMobile />
      <Routes>
        <Route path="/terms-condition" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/checkout"
          element={
            <RequireAuth>
              <Checkout />
            </RequireAuth>
          }
        />
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/compare" element={<Compare />} />
        <Route
          path="/invoice/:id"
          element={
            <RequireAuth>
              <Invoice2 />
            </RequireAuth>
          }
        />
        <Route
          path="/my-account"
          element={
            <RequireAuth>
              <MyAccount />
            </RequireAuth>
          }
        />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/shop/product-detail/:id" element={<ProductDetail />} />
        <Route path="/shop" element={<Shop2 />} />
        <Route path="/about" element={<About />} />
        <Route path="/members" element={<MemebersPage />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
      <Preloader />
    </Main>
  );
};

export default App;
