/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IAuthPayload, IUser } from "src/models";
import * as Types from "src/redux/types";

export default class AuthActions {
  public static login =
    (authPayload?: IAuthPayload) =>
    async (
      dispatch: DispatchType<Action<IAuthPayload>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.LOGIN_USER,
        payload: authPayload,
      });

  public static logout =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) => {
      dispatch({
        type: Types.LOGOUT,
      });
    };

  public static update =
    (user?: IUser) =>
    async (
      dispatch: DispatchType<Action<IUser>>,
      getState: () => IRootState
    ) => {
      dispatch({
        type: Types.LOGIN_UPDATE,
        payload: user,
      });
    };
}
