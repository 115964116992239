import { IProduct } from "src/models";
import * as Types from "src/redux/types";

type State = {
  products: IProduct[];
  isLoaded: boolean;
};

const initialState: State = {
  products: [],
  isLoaded: false,
};

const MartBestSellsProductReducer = (
  state = initialState,
  action: Action<IProduct[]>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.MART_BEST_SELLS_PRODUCT_LOAD: {
      const data = payload as IProduct[];

      return {
        ...state,
        products: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default MartBestSellsProductReducer;
