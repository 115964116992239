/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IStoreQrDiscount } from "src/models";
import * as Types from "src/redux/types";

export default class StoreQrDiscountActions {
  public static load =
    (storeQrDiscount?: IStoreQrDiscount) =>
    async (
      dispatch: DispatchType<Action<IStoreQrDiscount>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.STORE_QR_DISCOUNT_LOAD,
        payload: storeQrDiscount,
      });
}
