import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IOrder, IOrderItem } from "src/models";
import { useMartCart } from "src/redux/hooks";
import "./style.css";

const CheckOutOrderDetail = () => {
  const { items, total, deliveryChargeAmount } = useMartCart();
  return (
    <div className="col-lg-5">
      <div className="border p-40 cart-totals ml-30 mb-50">
        <div className="d-flex align-items-end justify-content-between mb-30">
          <h4>Your Order</h4>
          <h6 className="text-muted">Subtotal</h6>
        </div>
        <div className="divider-2 mb-30"></div>
        <div className="table-responsive order_table checkout">
          <table className="table no-border">
            <tbody>
              {items.map((item, index) => {
                const {
                  product: { id, images, title, qtyPerUnit, price, discount },
                  quantity,
                } = item;
                const linkTo = `/shop/product-detail/${id}`;
                return (
                  <tr>
                    <td className="image product-thumbnail">
                      <img src={config.BASE_URL + images[0].path} alt="#" />
                    </td>
                    <td>
                      <h6 className="w-160 mb-5">
                        <Link to={linkTo} className="text-heading">
                          {title} {qtyPerUnit}
                        </Link>
                      </h6>
                      <div className="product-rate-cover">
                        <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                        <span className="font-small ml-5 text-muted">
                          {" "}
                          (4.0)
                        </span>
                      </div>
                    </td>
                    <td>
                      <h6 className="text-muted pl-20 pr-20">x {quantity}</h6>
                    </td>
                    <td>
                      <h4 className="text-brand">
                        Rs{Utils.getDicountPrice(price, discount) * quantity}
                      </h4>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="payment ml-30">
        <h4 className="mb-30">Payment</h4>
        <div className="payment_option">
          <div className="custome-radio">
            <input
              className="form-check-input"
              required
              type="radio"
              name="payment_option"
              id="exampleRadios4"
              checked
            />
            <label
              className="form-check-label"
              htmlFor="exampleRadios4"
              data-bs-toggle="collapse"
              data-target="#checkPayment"
              aria-controls="checkPayment"
            >
              Cash on delivery
            </label>
          </div>
        </div>
        {/* <div className="payment-logo d-flex">
          <img
            className="mr-15"
            src="assets/imgs/theme/icons/payment-paypal.svg"
            alt=""
          />
          <img
            className="mr-15"
            src="assets/imgs/theme/icons/payment-visa.svg"
            alt=""
          />
          <img
            className="mr-15"
            src="assets/imgs/theme/icons/payment-master.svg"
            alt=""
          />
          <img src="assets/imgs/theme/icons/payment-zapper.svg" alt="" />
        </div> */}
        <button className="btn btn-fill-out btn-block mt-30">
          Place an Order<i className="fi-rs-sign-out ml-15"></i>
        </button>
      </div>
    </div>
  );
};

export default CheckOutOrderDetail;
