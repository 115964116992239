const HomeHeaderSliderData = [
  {
    imageUrl: "assets/imgs/slider/slider-1.jpg",
    title: () => (
      <>
        Don’t miss amazing
        <br /> grocery deals
      </>
    ),
    description: "Sign up for the daily newsletter",
  },
  {
    imageUrl: "assets/imgs/slider/slider-2.jpg",
    title: () => (
      <>
        Quick Delivery
        <br /> 24 hour services
      </>
    ),
    description: "Free delivery on above Rs 300 order.",
  },
];

const HomeBanners = [
  {
    imageUrl: "assets/imgs/banner/banner-1.png",
    title: () => (
      <>
        Everyday Fresh & <br />
        Clean with Our
        <br /> Products
      </>
    ),
    linkTo: "/shop",
  },
  {
    imageUrl: "assets/imgs/banner/banner-2.png",
    title: () => (
      <>
        Make your Breakfast
        <br /> Healthy and Easy
      </>
    ),
    linkTo: "/shop",
  },
  {
    imageUrl: "assets/imgs/banner/banner-3.png",
    title: () => (
      <>
        The best Organic <br />
        Products Online
      </>
    ),
    linkTo: "/shop",
  },
];

export { HomeHeaderSliderData, HomeBanners };
