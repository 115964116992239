/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Breadcrumb } from "src/components";
import { useAuth } from "src/redux/hooks";
import AccountDetail from "./components/AccountDetail";
import Addresses from "./components/Addresses";
import Dashboard from "./components/Dashboard";
import Orders from "./components/Orders";
import TrackOrder from "./components/TrackOrder";
import DeleteAccountRequest from "./components/delete-account-request";

const MyAccount = () => {
  const { logoutUser } = useAuth();

  return (
    <main className="main pages">
      <Breadcrumb pages={["My Account"]} />
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-menu">
                    <ul className="nav flex-column" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="dashboard-tab"
                          data-bs-toggle="tab"
                          href="#dashboard"
                          role="tab"
                          aria-controls="dashboard"
                          aria-selected="false"
                        >
                          <i className="fi-rs-settings-sliders mr-10"></i>
                          Dashboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="orders-tab"
                          data-bs-toggle="tab"
                          href="#orders"
                          role="tab"
                          aria-controls="orders"
                          aria-selected="false"
                        >
                          <i className="fi-rs-shopping-bag mr-10"></i>Orders
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="track-orders-tab"
                          data-bs-toggle="tab"
                          href="#track-orders"
                          role="tab"
                          aria-controls="track-orders"
                          aria-selected="false"
                        >
                          <i className="fi-rs-shopping-cart-check mr-10"></i>
                          Track Your Order
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="address-tab"
                          data-bs-toggle="tab"
                          href="#address"
                          role="tab"
                          aria-controls="address"
                          aria-selected="true"
                        >
                          <i className="fi-rs-marker mr-10"></i>My Address
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="account-detail-tab"
                          data-bs-toggle="tab"
                          href="#account-detail"
                          role="tab"
                          aria-controls="account-detail"
                          aria-selected="true"
                        >
                          <i className="fi-rs-user mr-10"></i>Account details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="delete-account-tab"
                          data-bs-toggle="tab"
                          href="#delete-account"
                          role="tab"
                          aria-controls="delete-account"
                          aria-selected="true"
                        >
                          <i className="fi-rs-user mr-10"></i>Delete Account
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" onClick={() => logoutUser()}>
                          <i className="fi-rs-sign-out mr-10"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content pl-50">
                    <Dashboard />
                    <Orders />
                    <TrackOrder />
                    <Addresses />
                    <AccountDetail />
                    <DeleteAccountRequest />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyAccount;
