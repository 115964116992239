import React, { Fragment } from "react";
import {
  Banner,
  DealsOfTheDay,
  CategorySlider,
  Deals,
  HomeSlider,
  PopularProducts,
  BestSellsProducts,
  Promotions,
} from "./components";

const Home = () => {
  return (
    <main className="main">
      <HomeSlider />
      <CategorySlider />
      <Banner />
      <PopularProducts />
      <Promotions />
      <BestSellsProducts />
      <DealsOfTheDay />
      {/* <Deals /> */}
    </main>
  );
};

export default Home;
