import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "src/redux/hooks";
import MobileMegaMenu from "./components/MobileMegaMenu";

const HeaderMobile = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [search, setSearch] = useState<string>("");

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (search !== "") {
      let path = `/shop?search=${search}`;

      navigate(path);
    }
  };
  return (
    <div className="mobile-header-active mobile-header-wrapper-style">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <Link to="/">
              <img src="assets/imgs/theme/logo512.png" alt="logo" />
            </Link>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <button className="close-style search-close">
              <i className="icon-top"></i>
              <i className="icon-bottom"></i>
            </button>
          </div>
        </div>
        <div className="mobile-header-content-area">
          <div className="mobile-search search-style-3 mobile-header-border">
            <form onSubmit={onSubmit}>
              <input
                type="text"
                placeholder="Search for items…"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="submit">
                <i className="fi-rs-search"></i>
              </button>
            </form>
          </div>
          <div className="mobile-menu-wrap mobile-header-border">
            {/* mobile menu start */}
            <nav>
              <ul className="mobile-menu font-heading">
                <li className="menu-item-has-children">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="menu-item-has-children">
                  <NavLink to="/about">About</NavLink>
                </li>
                <li className="menu-item-has-children">
                  <NavLink to="/shop">Shop</NavLink>
                </li>
                {/* <MobileMegaMenu /> */}

                {isLoggedIn ? (
                  <li className="menu-item-has-children">
                    <NavLink to="/my-account">My Account</NavLink>
                  </li>
                ) : (
                  <li className="menu-item-has-children">
                    <NavLink to="/login">Login</NavLink>
                  </li>
                )}

                <li className="menu-item-has-children">
                  <NavLink to="/contact">Contact</NavLink>
                </li>
                <li className="menu-item-has-children">
                  <a
                    href="https://najeebstore-api.resourceinn.com/recruitment/jobPost/1"
                    target="_blank"
                  >
                    Job Opportunity
                  </a>
                </li>
              </ul>
            </nav>
            {/* mobile menu end  */}
          </div>
          <div className="mobile-header-info-wrap">
            <div className="single-mobile-header-info">
              <Link to="/contact-us">
                <i className="fi-rs-marker"></i> Our location{" "}
              </Link>
            </div>
            <div className="single-mobile-header-info">
              <Link to={isLoggedIn ? "/my-account" : "/login"}>
                <i className="fi-rs-user"></i>
                {isLoggedIn ? "My Account" : "Log In"}
              </Link>
            </div>
            <div className="single-mobile-header-info">
              <a href="#">
                <i className="fi-rs-headphones"></i>051-2107676{" "}
              </a>
            </div>
          </div>
          <div className="mobile-social-icon mb-50">
            <h6 className="mb-15">Follow Us</h6>
            <a href="#" className="facebook">
              <img
                src="assets/imgs/theme/icons/icon-facebook-white.svg"
                alt=""
              />
            </a>
            <a href="#" className="twitter">
              <img
                src="assets/imgs/theme/icons/icon-twitter-white.svg"
                alt=""
              />
            </a>
            <a href="#" className="instagram">
              <img
                src="assets/imgs/theme/icons/icon-instagram-white.svg"
                alt=""
              />
            </a>

            <a href="#" className="youtube">
              <img
                src="assets/imgs/theme/icons/icon-youtube-white.svg"
                alt=""
              />
            </a>
          </div>
          <div className="site-copyright">
            {" "}
            Copyright 2022 © Najeeb Mart. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobile;
