import React from "react";

interface IProps {
  label: string;
  isActive?: boolean;
  description: string;
}

const TimeLine: React.FC<IProps> = ({ label, isActive, description }) => {
  return (
    <li>
      <div className="direction-r">
        <div className="flag-wrapper">
          <span className={`flag ${isActive ? "flag-active" : ""}`}>
            {label}
          </span>
          <span className="time-wrapper">
            {/* <span className="time">2011 - 2013</span> */}
          </span>
        </div>
        <div className="desc">{description}</div>
      </div>
    </li>
  );
};

export default TimeLine;
