import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { ProductService } from "src/redux/services";
import { IAddress, IProduct, IProductPagination } from "src/models";
import { ProductPaginationActions } from "../actions";

const useProducts = () => {
  const pageSizes = [15, 30, 50, 100];
  const {
    currentPage,
    pageData,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    pageSize: currentPageSize,
  } = useAppSelector((state) => state.martProductPagination);
  const { isLoaded: isProductCategoryLoaded, productCategories } =
    useAppSelector((state) => state.martDiscountProductCategory);
  const { products: discountProducts, isLoaded: isDiscountProductLoaded } =
    useAppSelector((state) => state.martDiscountProducts);

  const { products: bestSellsProducts, isLoaded: isBestSellsLoaded } =
    useAppSelector((state) => state.martBestSellsProducts);
  const dispatch = useDispatch();
  //const [pageData, setPageData] = useState<IProductPagination[]>([]);
  const [relatedProducts, setRelatedProducts] = useState<IProduct[]>([]);
  const [pageSize, setPageSize] = useState<number>(currentPageSize);
  const [products, setProducts] = useState<IProduct[]>(
    pageData[currentPage] || []
  );

  const {
    //martWebsiteAddress: { isLoaded, addresses },
    martWebsiteAuth: { isLoggedIn },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setProducts(pageData[currentPage] || []);
  }, [pageData]);

  const findAll = async (
    page: number,
    pageSize: number,
    categorydId: number,
    subCategoryIds: number[],
    searchText: string
  ) => {
    try {
      setLoading(true);
      const response = await ProductService.findAll(
        page,
        pageSize,
        categorydId,
        subCategoryIds,
        searchText
      );
      if (response.response) {
        dispatch(ProductPaginationActions.load(response.response));
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findProductById = async (id: number): Promise<IProduct | undefined> => {
    const existingProduct = pageData[currentPage]?.find(
      (product: IProduct) => product.id === id
    );
    if (existingProduct) {
      return existingProduct;
    }
    try {
      setLoading(true);
      const response = await ProductService.findById(id);
      return response.response;
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  const findRelatedProducts = async (
    productCategoryId: number,
    productId: number
  ) => {
    try {
      const response = await ProductService.findRelatedProducts(
        productCategoryId,
        productId
      );
      response.response && setRelatedProducts(response.response);
    } catch (err: any) {}
  };

  const findDiscountProductsWithCategory = async () => {
    try {
      setLoading(true);
      const response = await ProductService.findDiscountProductsWithCategory();

      if (response.response) {
        dispatch(
          ProductPaginationActions.discountProductCategoryLoad(
            response.response
          )
        );
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const loadDiscountProducts = async () => {
    try {
      setLoading(true);
      const response = await ProductService.findAllDiscountProducts();
      if (response.response) {
        dispatch(
          ProductPaginationActions.loadDiscountProducts(response.response)
        );
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const loadBestSellsProducts = async (limit: number) => {
    try {
      setLoading(true);
      const response = await ProductService.findBestSellsProducts(limit);
      if (response.response) {
        dispatch(
          ProductPaginationActions.loadBestSellsProducts(response.response)
        );
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  return {
    currentPage,
    products,
    loading,
    //isLoaded,
    pageData,
    isLoggedIn,
    pageSizes,
    pageSize,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    relatedProducts,
    isProductCategoryLoaded,
    productCategories,
    isDiscountProductLoaded,
    discountProducts,
    bestSellsProducts,
    isBestSellsLoaded,
    loadBestSellsProducts,
    setPageSize,
    findAll,
    findProductById,
    findRelatedProducts,
    findDiscountProductsWithCategory,
    loadDiscountProducts,
  };
};

export default useProducts;
