import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IProps {
  categoryTitle: string;
}
const EmptyShop: React.FC<IProps> = ({ categoryTitle }) => {
  const [searchText, setSearchText] = useState<string>();
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (searchText && searchText !== "") {
      navigate(`/shop?search=${searchText}`);
    }
  };
  return (
    <main className="main page-404">
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <p className="mb-20">
                <img
                  src="assets/imgs/page/page-404.png"
                  alt=""
                  className="hover-up"
                />
              </p>
              <h1 className="display-2 mb-30">No Products Found</h1>
              <p className="font-lg text-grey-700 mb-30">
                No products found in {categoryTitle}. Comback for the{" "}
                {categoryTitle}. We are updating the menu.
              </p>
              <div className="search-form">
                <form action="#" onSubmit={onSubmit}>
                  <input
                    type="text"
                    placeholder="Search…"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <button type="submit">
                    {" "}
                    <i className="fi-rs-search"></i>{" "}
                  </button>
                </form>
              </div>
              <Link
                className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                to="/shop"
              >
                <i className="fi-rs-home mr-5"></i> Shop
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmptyShop;
