/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { IAddress } from "src/models";
import { useAddress } from "src/redux/hooks";
import swal from "sweetalert";
import AddOrEditAddress from "./AddOrEditAddress";

const Addresses = () => {
  const {
    isLoaded,
    isLoggedIn,
    addresses,
    findAll,
    getDefaultAddress,
    removeById,
  } = useAddress();
  const defaultAddress = getDefaultAddress();
  const [editAddress, setEditAddress] = useState<IAddress>();
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);

  const removeAddress = (address: IAddress) => {
    swal({
      title: `Are you sure to delete this address?`,
      text: address.address,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await removeById(address.id, () => {
          swal(`Success! Your address has been removed!`, {
            icon: "success",
          });
        });
      }
    });
  };
  return (
    <div
      className="tab-pane fade"
      id="address"
      role="tabpanel"
      aria-labelledby="address-tab"
    >
      <div className="row">
        <div className="col-lg-12">
          <button
            className="submit submit-auto-width"
            onClick={() => setShowAddressModal(true)}
          >
            Add New Address
          </button>
        </div>
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-0">
            <div className="card-header">
              <h3 className="mb-0">Address</h3>
            </div>
            {addresses.map((address) => (
              <div className="card-body" key={address.id}>
                <address>
                  {address.apartment} {address.address}
                </address>
                <p>{address.city}</p>
                <a
                  onClick={() => {
                    setEditAddress(address);
                    setShowAddressModal(true);
                  }}
                  className="btn-small"
                >
                  Edit
                </a>{" "}
                |{" "}
                <a onClick={() => removeAddress(address)} className="btn-small">
                  Delete
                </a>
              </div>
            ))}
          </div>
        </div>
        {defaultAddress !== undefined && (
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h5 className="mb-0">Shipping Address</h5>
              </div>
              <div className="card-body">
                <address>
                  {defaultAddress.apartment} {defaultAddress.address}
                </address>
                <p>{defaultAddress.city}</p>
                <a href="#" className="btn-small">
                  Edit
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddOrEditAddress
        show={showAddressModal}
        currentAddress={editAddress}
        onDismiss={() => {
          setEditAddress(undefined);
          setShowAddressModal(false);
        }}
      />
    </div>
  );
};

export default Addresses;
