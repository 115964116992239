/* eslint-disable jsx-a11y/scope */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "src/components";
import config from "src/config/config";
import Utils from "src/helper";
import { useMartCart } from "src/redux/hooks";
import ItemCart from "./components/ItemCart";
import "./style.css";

const Cart = () => {
  const {
    items,
    size,
    total,
    clear,
    findAll,
    isEmpty,
    deliveryChargeAmount,
    deliveryChargeMessage,
  } = useMartCart();

  useEffect(() => {
    (async () => {
      if (!isEmpty) {
        findAll();
      }
    })();
  }, []);

  return (
    <main className="main">
      <Breadcrumb pages={["Shop", "Cart"]} />
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-8 mb-40">
            <h1 className="heading-2 mb-10">Your Cart</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand">{size}</span> products in
                your cart
              </h6>
              <h6 className="text-body">
                <a onClick={clear} className="text-muted">
                  <i className="fi-rs-trash mr-5"></i>Clear Cart
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive shopping-summery">
              <table className="table table-wishlist">
                <thead>
                  <tr className="main-heading">
                    <th className="custome-checkbox start pl-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="exampleCheckbox11"
                        value=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox11"
                      ></label>
                    </th>
                    <th scope="col" colSpan={2}>
                      Product
                    </th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Subtotal</th>
                    <th scope="col" className="end">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty ? (
                    items.map((item, index) => (
                      <ItemCart cartItem={item} key={index} />
                    ))
                  ) : (
                    <tr className="text-center product-des product-name">
                      <td colSpan={6}>
                        <h6 className="mb-5">
                          Look like you haven't add any item in your cart.
                        </h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="divider-2 mb-30"></div>
            <div className="cart-action d-flex justify-content-between">
              <Link className="btn " to="/shop">
                <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
              </Link>
            </div>
          </div>
          {!isEmpty && (
            <div className="col-lg-4">
              <div className="border p-md-4 cart-totals ml-30">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Subtotal</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">Rs{total}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Shipping</h6>
                          <small>{deliveryChargeMessage}</small>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">
                            {deliveryChargeAmount > 0
                              ? `Rs ${deliveryChargeAmount}`
                              : "Free"}
                          </h5>
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Total</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">
                            Rs{total + deliveryChargeAmount}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Link to="/checkout" className="btn mb-20 w-100">
                  Proceed To CheckOut<i className="fi-rs-sign-out ml-15"></i>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Cart;
