import { IAxiosResponse } from "src/models";
import axiosInstance from "./axios";

export default class EmailSubscribeService {
  private static readonly url: string = "/subscribe-email";
  public static async subscribe(
    email: string
  ): Promise<IAxiosResponse<string>> {
    const response = await axiosInstance.post(this.url, {
      email,
      isActive: true,
    });
    return response.data;
  }
}
