import axios, { AxiosResponse } from "axios";
import * as Types from "./../types";
import { store } from "./../index";
import { IAxiosResponse } from "./../../models";
import config from "../../config/config";

const axiosInstance = axios.create({
  baseURL: `${config.BASE_URL}api/v5.0`,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": config.API_KEY,
  },
});

axiosInstance.interceptors.request.use(
  (request) => {
    const { token } = store.getState().martWebsiteAuth;
    if (token) {
      request!.headers!["x-access-token"] = `${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.status === 401) {
      store.dispatch({ type: Types.LOGOUT });
    }
    return Promise.reject(error);
  }
);

export type ApiResponse<T> = AxiosResponse<IAxiosResponse<T>>;

export default axiosInstance;
