import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { MartCartActions } from "src/redux/actions";
import { MartCartService } from "src/redux/services";
import { ICartItem, IProduct } from "src/models";
import Utils from "src/helper";
const useMartCart = () => {
  const dispatch = useDispatch();
  const {
    martWebsiteAuth: { isLoggedIn },
    deliveryCharges: { deliveryCharge },
    martCart: { isLoaded, items },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const findAll = async () => {
    try {
      setLoading(true);
      const ids = items.map((item) => item.product.id);
      const response = await MartCartService.findAllProducts(ids);
      const products = response.response;
      if (products) {
        dispatch(
          MartCartActions.load(products.map((p) => getCartItem(items, p)))
        );
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    dispatch(MartCartActions.clear());
  };

  const addCartItem = (cartItem: ICartItem) => {
    dispatch(MartCartActions.addItem(cartItem));
  };

  const updateCartItem = (cartItem: ICartItem) => {
    dispatch(MartCartActions.updateItem(cartItem));
  };

  const getCartItem = (items: ICartItem[], product: IProduct): ICartItem => {
    const cartItem = items.find((item) => item.product.id === product.id);
    return {
      ...cartItem!,
      product: product,
    };
  };

  let total = 0;
  items.map(({ product: { price, discount }, quantity }) => {
    total += Utils.getDicountPrice(price, discount) * quantity;
  });

  const removeItem = (id: number) => {
    dispatch(MartCartActions.removeItem(id));
  };

  const isItemExistInCart = (id: number): boolean => {
    return items.find((item) => item.product.id === id) !== undefined;
  };

  const deliveryChargeMessage =
    total >= deliveryCharge.minAmount
      ? ""
      : `Free Delivery On Shoppin Of Rs ${deliveryCharge.minAmount}`;
  const deliveryChargeAmount =
    total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;

  return {
    loading,
    isLoggedIn,
    isLoaded,
    items,
    size: items.length,
    isEmpty: items.length === 0,
    total,
    deliveryChargeMessage,
    deliveryChargeAmount,
    isItemExistInCart,
    clear,
    findAll,
    addCartItem,
    updateCartItem,
    removeItem,
  };
};

export default useMartCart;
