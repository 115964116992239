/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IProduct } from "src/models";
import { useMartWishlist } from "src/redux/hooks";

interface IProps {
  product: IProduct;
  onQuickViewClick: (product: IProduct) => void;
}

const RelatedProductItem: React.FC<IProps> = ({
  product,
  onQuickViewClick,
}) => {
  const { id, title, qtyPerUnit, images, price, discount } = product;
  const { isWishlisted, addWishlistItem, removeWishlistItem } =
    useMartWishlist();
  const isProductWishlisted = isWishlisted(id);

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(id);
    } else {
      addWishlistItem(product);
    }
  };
  const linkTo = `/shop/product-detail/${id}`;
  return (
    <div className="col-lg-3 col-md-4 col-12 col-sm-6">
      <div className="product-cart-wrap hover-up">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <Link to={linkTo} tabIndex={0}>
              <img
                className="default-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
              <img
                className="hover-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
            </Link>
          </div>
          <div className="product-action-1">
            <a
              aria-label="Quick view"
              className="action-btn small hover-up"
              data-bs-toggle="modal"
              onClick={() => onQuickViewClick(product)}
            >
              <i className="fi-rs-search"></i>
            </a>
            <a
              aria-label="Add To Wishlist"
              className="action-btn small hover-up"
              onClick={addOrRemoveWishlist}
              tabIndex={0}
            >
              <i
                className={
                  isProductWishlisted ? "fa-solid fa-heart" : "fi-rs-heart"
                }
              ></i>
            </a>
            <Link
              aria-label="Compare"
              className="action-btn small hover-up"
              to="/compare"
              tabIndex={0}
            >
              <i className="fi-rs-shuffle"></i>
            </Link>
          </div>
          {discount > 0 && (
            <div className="product-badges product-badges-position product-badges-mrg">
              <span className="best">{Utils.roundTo1Digit(discount)}% off</span>
            </div>
          )}
        </div>
        <div className="product-content-wrap">
          <h2>
            <Link to={linkTo}>
              {title} {qtyPerUnit}
            </Link>
          </h2>
          <div className="rating-result" title="90%">
            <span></span>
          </div>
          <div className="product-price">
            <span>Rs{Utils.getDicountPrice(price, discount)}</span>
            {discount > 0 && <span className="old-price">{price}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProductItem;
