import { ICategory } from "src/models";
import * as Types from "src/redux/types";

type State = {
  categories: ICategory[];
  isLoaded: boolean;
};

const initialState: State = {
  categories: [],
  isLoaded: false,
};

const MartCategoriesReducer = (
  state = initialState,
  action: Action<ICategory[]>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.MART_CATEGORIES_LOAD: {
      const data = payload as ICategory[];
      return {
        ...state,
        categories: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default MartCategoriesReducer;
