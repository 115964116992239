/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IProduct } from "src/models";
import * as Types from "src/redux/types";

export default class MartWishlistActions {
  public static load =
    (products: IProduct[]) =>
    async (
      dispatch: DispatchType<Action<IProduct[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_WISHLIST_LOAD,
        payload: products,
      });

  public static addItem =
    (product: IProduct) =>
    async (
      dispatch: DispatchType<Action<IProduct>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_WISHLIST_ADD_ITEM,
        payload: product,
      });

  public static removeItem =
    (id: number) =>
    async (
      dispatch: DispatchType<Action<number>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_WISHLIST_ITEM_REMOVE,
        payload: id,
      });

  public static clear =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) =>
      dispatch({
        type: Types.MART_WISHLIST_CLEAR,
      });
}
