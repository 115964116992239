/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth, useMartCategories, useStore } from "src/redux/hooks";
import SubscribeEmail from "../subscribe";

const Footer = () => {
  const { storeQrDiscount } = useStore();
  const { isLoggedIn } = useAuth();
  const { chunkCategories, findAll, isLoaded } = useMartCategories();

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await findAll();
      }
    })();
  }, [isLoaded]);

  return (
    <footer className="main">
      <section className="newsletter wow fadeIn animated mb-15">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="position-relative newsletter-inner">
                <div className="newsletter-content">
                  <h2 className="mb-20">
                    Stay home & get your daily <br /> needs from our shop
                  </h2>
                  <p className="mb-45">
                    Start You'r Daily Shopping with{" "}
                    <span className="text-brand">Najeeb Mart & Pharmacy</span>
                  </p>
                  {/* <SubscribeEmail /> */}
                </div>
                <img
                  src="/assets/imgs/banner/slider 2 img.png"
                  alt="newsletter"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="featured wow fadeIn animated section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-1.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Best prices & offers</h3>
                  <p>Orders Rs 300 or more</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-2.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Free delivery</h3>
                  <p>24/7 amazing services</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-3.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Great daily deal</h3>
                  <p>When you sign up</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-4.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Wide assortment</h3>
                  <p>Mega Discounts</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-5.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Easy returns</h3>
                  <p>Within 30 days</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 d-xl-none">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn  animated">
                <div className="banner-icon">
                  <img src="/assets/imgs/theme/icons/icon-6.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Safe delivery</h3>
                  <p>Within 30 days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding footer-mid">
        <div className="container pt-15 pb-20">
          <div className="row">
            <div className="col">
              <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0">
                <div className="logo wow fadeIn animated mb-30">
                  <Link to="/" className="mb-15">
                    <img
                      src="/assets/imgs/theme/logo512.png"
                      alt="logo"
                      style={{ width: "60%" }}
                    />
                  </Link>
                  <p className="font-lg text-heading">Awesome grocery store</p>
                </div>
                <ul className="contact-infor">
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-location.svg"
                      alt=""
                    />
                    <strong>Address: </strong>{" "}
                    <span>
                      Ground Floor, jamal plaza, F-10 Markaz, islamabd. Markaz,
                      Pakistan
                    </span>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-contact.svg"
                      alt=""
                    />
                    <strong>Call Us:</strong>
                    <span>051-2107676</span>
                  </li>
                  {/* <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-email-2.svg"
                      alt=""
                    />
                    <strong>Email:</strong>
                    <span>
                      <a
                        href="cdn-cgi/l/email-protection.html"
                        className="__cf_email__"
                        data-cfemail="fd8e9c9198bdb3988e89d39e9290"
                      >
                        [email&#160;protected]
                      </a>
                    </span>
                  </li> */}
                  <li>
                    <img src="/assets/imgs/theme/icons/icon-clock.svg" alt="" />
                    <strong>Hours:</strong>
                    <span>06:00 AM - 12:00 PM</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-link-widget col">
              <h4 className="widget-title wow fadeIn animated">Company</h4>
              <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                <li>
                  <Link to="/shop">Shop</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>

                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="footer-link-widget col">
              <h4 className="widget-title wow fadeIn animated">Account</h4>
              <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                {!isLoggedIn && (
                  <li>
                    <Link to="/login">Log In</Link>
                  </li>
                )}
                <li>
                  <Link to="/cart">View Cart</Link>
                </li>
                <li>
                  <Link to="/wishlist">My Wishlist</Link>
                </li>
                <li>
                  <Link to={isLoggedIn ? "/my-account" : "/login"}>
                    My Account
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-link-widget col">
              <h4 className="widget-title wow fadeIn animated">Categories</h4>
              <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                {chunkCategories[0]?.map((category) => (
                  <li>
                    <Link to={`/shop?category=${category.id}`}>
                      {category.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-link-widget col">
              <h4 className="widget-title wow fadeIn animated">Categories</h4>
              <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                {chunkCategories[1]?.map((category) => (
                  <li>
                    <Link to={`/shop?category=${category.id}`}>
                      {category.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-link-widget widget-install-app col">
              <h4 className="widget-title wow fadeIn animated">Install App</h4>
              <p className="wow fadeIn animated">
                From App Store or Google Play
              </p>
              <div className="download-app wow fadeIn animated">
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/najeeb-mart-pharmacy/id1559907067"
                  className="hover-up mb-sm-2 mb-lg-0"
                >
                  <img
                    className="active"
                    src="assets/imgs/theme/app-store.jpg"
                    alt=""
                  />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.najeeb.mart.pharmacy.shopping.online.free.delivery.grocery"
                  className="hover-up mb-sm-2"
                >
                  <img src="assets/imgs/theme/google-play.jpg" alt="" />
                </a>
              </div>
              {/* <p className="mb-20 wow fadeIn animated">
                Secured Payment Gateways
              </p>
             
              <img
                className="wow fadeIn animated"
                src="assets/imgs/theme/payment-method.png"
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-30 wow fadeIn animated">
        <div className="row align-items-center">
          <div className="col-12 mb-30">
            <div className="footer-bottom"></div>
          </div>
          <div className="col-xl-2 col-lg-6 col-md-6">
            <p className="font-sm mb-0">
              &copy; 2022, <strong className="text-brand">Najeeb Mart</strong>
              <br />
              All rights reserved
            </p>
          </div>
          <div className="col-xl-7  col-lg-6 text-center d-none d-xl-block">
            <div className="row">
              {storeQrDiscount?.admins?.map((admin) => (
                <div className="col-xl-4  col-lg-6">
                  <div className="hotline d-lg-inline-flex mr-30">
                    <img
                      src="/assets/imgs/theme/icons/phone-call.svg"
                      alt="hotline"
                    />
                    <p>
                      {admin.phone}
                      <span>{admin.storeName}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-3  col-lg-6  col-md-6 text-end d-none d-md-block">
            <div className="mobile-social-icon">
              <h6>Follow Us </h6>
              <a href="#" className="facebook">
                <img
                  src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                  alt=""
                />
              </a>
              <a href="#" className="twitter">
                <img
                  src="/assets/imgs/theme/icons/icon-twitter-white.svg"
                  alt=""
                />
              </a>
              <a href="#" className="instagram">
                <img
                  src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                  alt=""
                />
              </a>

              <a href="#" className="youtube">
                <img
                  src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                  alt=""
                />
              </a>
            </div>
            {/* <p className="font-sm">
              Up to 15% discount on your first subscribe
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
