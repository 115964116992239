import { useState } from "react";
import { useDispatch } from "react-redux";
import { IOrder, IOrderItem } from "src/models";
import { useAppSelector } from "src/redux";
import { OrderActions } from "src/redux/actions";
import { MartOrderService } from "src/redux/services";
const useMartOrders = () => {
  const dispatch = useDispatch();
  const {
    martWebsiteAuth: { isLoggedIn },
    martWebsiteOrders: { isLoaded, orders },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const save = async (
    order: IOrder,
    onSuccess: () => void,
    onError: (error: string) => void
  ) => {
    try {
      setLoading(true);
      const response = await MartOrderService.save(order);
      if (response.response) {
        dispatch(OrderActions.save(response.response));
        onSuccess();
      } else {
        onError(response.message);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findAll = async () => {
    try {
      setLoading(true);
      const response = await MartOrderService.findAll();
      response.response && dispatch(OrderActions.load(response.response));
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const editOrderItem = async (
    data: {
      order: IOrder;
      orderItem: IOrderItem;
    },
    onSuccess: () => void
  ) => {
    try {
      setLoading(true);
      const response = await MartOrderService.editOrderItem(data);
      response.response && dispatch(OrderActions.update(data.order));
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findById = async (id: number): Promise<IOrder | undefined> => {
    try {
      const order = orders.find((order) => order.id === id);
      if (order !== undefined) {
        return order;
      }
      setLoading(true);

      const response = await MartOrderService.findById(id);
      return response.response;
    } catch (err: any) {
      console.log(err);
      return undefined;
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const resetOrders = () => {
    dispatch(OrderActions.reset());
  };

  const removeOrderItem = async (
    data: {
      order: IOrder;
      orderItem: IOrderItem;
    },
    onSuccess: () => void
  ) => {
    try {
      setLoading(true);
      const response = await MartOrderService.removeOrderItem(data);
      response.response && dispatch(OrderActions.update(data.order));
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async (order: IOrder, onSuccess: () => void) => {
    try {
      setLoading(true);
      const response = await MartOrderService.cancelOrder(order);
      response.response && dispatch(OrderActions.update(order));
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isLoggedIn,
    isLoaded,
    orders,
    findAll,
    editOrderItem,
    findById,
    removeOrderItem,
    cancelOrder,
    save,
  };
};

export default useMartOrders;
