import { IAuthPayload, IAddress } from "src/models";
import * as Types from "src/redux/types";

type State = {
  addresses: IAddress[];
  isLoaded: boolean;
};

const initialState: State = {
  addresses: [],
  isLoaded: false,
};

const AddressReducer = (
  state = initialState,
  action: Action<IAddress[] | IAddress | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.ADDRESSES_LOAD: {
      const data = payload as IAddress[];
      return {
        ...state,
        addresses: data,
        isLoaded: true,
      };
    }

    case Types.ADDRESS_SAVE: {
      const data = payload as IAddress;
      return {
        ...state,
        addresses: [...state.addresses, data],
      };
    }

    case Types.ADDRESS_DELETE: {
      const id = payload as number;
      return {
        ...state,
        addresses: state.addresses.filter((address) => address.id !== id),
      };
    }

    case Types.ADDRESS_EDIT: {
      const data = payload as IAddress;
      return {
        ...state,
        addresses: state.addresses.map((address) =>
          address.id === data.id ? data : address
        ),
      };
    }

    case Types.ADDRESS_RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default AddressReducer;
