/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { ICartItem } from "src/models";
import { useMartCart } from "src/redux/hooks";

interface IProps {
  cartItem: ICartItem;
}

const ItemCartMobile: React.FC<IProps> = ({ cartItem }) => {
  const { removeItem, updateCartItem } = useMartCart();
  const {
    product: { price, images, title, qtyPerUnit, discount, id },
    quantity,
  } = cartItem;
  const linkTo = `/shop/product-detail/${id}`;

  return (
    <li>
      <div className="shopping-cart-img">
        <Link to={linkTo}>
          <img alt="Nest" src={config.BASE_URL + images[0].path} />
        </Link>
      </div>
      <div className="shopping-cart-title">
        <h4>
          <a href="shop-product-right.html">
            {title} {qtyPerUnit}
          </a>
        </h4>
        <h4>
          <span>{quantity} × </span>Rs
          {Utils.getDicountPrice(price, discount)}
        </h4>
      </div>
      <div className="shopping-cart-delete">
        <a onClick={() => removeItem(id)}>
          <i className="fi-rs-cross-small"></i>
        </a>
      </div>
    </li>
  );
};

export default ItemCartMobile;
