/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CategoryItem from "src/components/category-item";
import { ICategory } from "src/models";
import { useMartCategories } from "src/redux/hooks";
import "./headercategorystyle.css";

const HeaderCategory = () => {
  const { findAll, chunkCategories, isLoaded } = useMartCategories();
  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await findAll();
      }
    })();
  }, [isLoaded]);

  console.log(chunkCategories);
  return (
    <div className="main-categori-wrap d-none d-lg-block">
      <a className="categories-button-active " href="#">
        <span className="fi-rs-apps"></span> <span className="et">Browse</span>{" "}
        All Categories
        <i className="fi-rs-angle-down"></i>
      </a>
      <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading">
        <div className="d-flex categori-dropdown-inner">
          {chunkCategories.map((chunkCategory, index) => (
            <ul key={index}>
              {chunkCategory.map((category: ICategory) => (
                <CategoryItem category={category} key={category.id} />
              ))}
            </ul>
          ))}
        </div>
        {/* <div className="more_slide_open" style={{ display: "none" }}>
          <div className="d-flex categori-dropdown-inner">
            <ul>
              <li>
                <a href="shop-grid-right.html">
                  {" "}
                  <img src="/assets/imgs/theme/icons/icon-1.svg" alt="" />
                  Milks and Dairies
                </a>
              </li>
              <li>
                <a href="shop-grid-right.html">
                  {" "}
                  <img src="/assets/imgs/theme/icons/icon-2.svg" alt="" />
                  Clothing & beauty
                </a>
              </li>
            </ul>
            <ul className="end">
              <li>
                <a href="shop-grid-right.html">
                  {" "}
                  <img src="/assets/imgs/theme/icons/icon-3.svg" alt="" />
                  Wines & Drinks
                </a>
              </li>
              <li>
                <a href="shop-grid-right.html">
                  {" "}
                  <img src="/assets/imgs/theme/icons/icon-4.svg" alt="" />
                  Fresh Seafood
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="more_categories">
          {" "}
          <span className="icon"></span>{" "}
          <span className="heading-sm-1">Show more...</span>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderCategory;
