import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { useCountDownTimer } from "src/hooks";
import { IProduct } from "src/models";

interface IProps {
  product: IProduct;
}

const ItemDiscountProduct: React.FC<IProps> = ({ product }) => {
  const { timerDays, timerHours, timerMinutes, timerSeconds } =
    useCountDownTimer(new Date("May 30,2022 "));
  const { id, title, qtyPerUnit, images, price, discount } = product;

  const linkTo = `/shop/product-detail/${id}`;
  return (
    <div className="col-xl-3 col-lg-4 col-md-6">
      <div className="product-cart-wrap style-2">
        {discount > 0 && (
          <div className="product-badges product-badges-position product-badges-mrg">
            <span className="best">{Utils.roundTo1Digit(discount)}% off</span>
          </div>
        )}
        <div className="product-img-action-wrap">
          <div className="product-img">
            <Link to={linkTo}>
              <img src={config.BASE_URL + images[0].path} alt="" />
            </Link>
          </div>
        </div>
        <div className="product-content-wrap">
          {/* <div className="deals-countdown-wrap">
            <div
              className="deals-countdown"
              data-countdown="2025/03/25 00:00:00"
            ></div>
          </div> */}
          <div className="deals-content">
            <h2>
              <Link to={linkTo}>
                {title} {qtyPerUnit}
              </Link>
            </h2>
            <div className="product-rate-cover">
              <div className="product-rate d-inline-block">
                <div className="product-rating" style={{ width: "90%" }}></div>
              </div>
              <span className="font-small ml-5 text-muted"> (4.0)</span>
            </div>
            <div>
              <span className="font-small text-muted">{qtyPerUnit}</span>
            </div>
            <div className="product-card-bottom">
              <div className="product-price">
                <span>Rs{Utils.getDicountPrice(price, discount)}</span>
                {discount > 0 && <span className="old-price">{price}</span>}
              </div>
              <div className="add-cart">
                <Link className="add" to={linkTo}>
                  <i className="fi-rs-shopping-cart mr-5"></i>Add{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDiscountProduct;
