// user
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_UPDATE = "LOGIN_UPDATE";
export const LOGOUT = "LOGOUT";

export const ADDRESSES_LOAD = "ADDRESSES_LOAD";
export const ADDRESS_SAVE = "ADDRESS_SAVE";
export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_EDIT = "ADDRESS_EDIT";
export const ADDRESS_SET_DEFAULT = "ADDRESS_SET_DEFAULT";
export const ADDRESS_RESET = "ADDRESS_RESET";

export const ORDERS_LOAD = "ORDERS_LOAD";
export const ORDERS_SAVE = "ORDERS_SAVE";
export const ORDERS_DELETE = "ORDERS_DELETE";
export const ORDERS_EDIT = "ORDERS_EDIT";
export const ORDERS_RESET = "ORDERS_RESET";

export const PRODUCT_PAGE_LOAD = "PRODUCT_PAGE_LOAD";

export const MART_CART_CLEAR = "MART_CART_CLEAR";
export const MART_CART_LOAD = "MART_CART_LOAD";
export const MART_CART_ADD_ITEM = "MART_CART_ADD_ITEM";
export const MART_CART_UPDATE_ITEM = "MART_CART_UPDATE_ITEM";
export const MART_CART_REMOVE = "MART_CART_REMOVE";

export const MART_WISHLIST_CLEAR = "MART_WISHLIST_CLEAR";
export const MART_WISHLIST_LOAD = "MART_WISHLIST_LOAD";
export const MART_WISHLIST_ADD_ITEM = "MART_WISHLIST_ADD_ITEM";
export const MART_WISHLIST_ITEM_REMOVE = "MART_WISHLIST_ITEM_REMOVE";

export const DELIVERY_CHARGES_LOAD = "DELIVERY_CHARGES_LOAD";

export const MART_DISCOUNT_PRODUCT_CATEGORY_LOAD =
  "MART_DISCOUNT_PRODUCT_CATEGORY_LOAD";

export const MART_BEST_SELLS_PRODUCT_LOAD = "MART_BEST_SELLS_PRODUCT_LOAD";

export const MART_CATEGORIES_LOAD = "MART_CATEGORIES_LOAD";
export const STORE_QR_DISCOUNT_LOAD = "STORE_QR_DISCOUNT_LOAD";
export const SHOPS_LOAD = "SHOPS_LOAD";
export const MART_DISCOUNT_PRODUCTS_LOAD = "MART_DISCOUNT_PRODUCTS_LOAD";
