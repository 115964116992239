/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { useProducts } from "src/redux/hooks";
import ItemBestSellProduct from "../../shop/components/ItemBestSellProduct";

const Promotions = () => {
  return (
    <section className="section-padding pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-flex">
            <a href="https://oragreenwater.com" target="_blank">
              <img
                src="/assets/imgs/banner/banner-4.png"
                className="banner-image"
              />
            </a>
          </div>
          <div className="col-lg-9 col-md-12">
            <a href="https://najeebmarketing.com/" target="_blank">
              <img
                src="/assets/imgs/banner/marketing-banner.jpg"
                className="banner-image"
              />
              {/* <div
                style={{
                  backgroundImage: `url(/assets/imgs/banner/marketing-banner.jpg)`,
                  height: 520,
                  borderRadius: 30,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              ></div> */}
            </a>
          </div>
          {/*End Col-lg-9*/}
        </div>
      </div>
    </section>
  );
};

export default Promotions;
