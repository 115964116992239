import React from "react";

const services = [
  {
    imageSrc: "assets/imgs/theme/icons/icon-1.svg",
    title: "Purchase in Easy way",
    description: `Select your desired needy grocery item in different categories
              such as Grocery and Staples, Personal Care, Baby Kids items &
              Toys, Beverages, Breakfast & Dairy Items, Biscuits Chocolates &
              Snacks, Frozen Foods & Noodles Sauces, Ice creams, Electronic &
              Mobile accessories, Household Needs and Pet Care. You can choose
              items from thousands of available products while enjoying multiple
              benefits.`,
  },
  {
    imageSrc: "assets/imgs/theme/icons/icon-2.svg",
    title: "Wide Assortment",
    description: `With over 5000 products and over a 200 brands in our catalogue,
              you will find everything you are looking for. Right from Rice and
              Dals, Spices and Seasonings to Packaged products, Beverages,
              Personal care products, frozen, and non-perishable groceries,
              health and beauty supplies, household, gift and baby items.`,
  },
  {
    imageSrc: "assets/imgs/theme/icons/icon-3.svg",
    title: "Free Delivery",
    description: `Najeeb Pharmacy in Islamabad, providing online medicine delivery
              without any delivery charges. When you are running out of your
              regular medicines, Najeeb pharmacy will send you the medicines
              with express delivery service without any additional delivery
              charges to restock your medicines. It’s easy to use app, search
              medicines, explore health issues by condition and order to get
              medicines at your door step.`,
  },
  {
    imageSrc: "assets/imgs/theme/icons/icon-4.svg",
    title: "Easy Returns",
    description: ` We have customers friendly very easy return policy after receiving
              your order by chance if you got wrong item or any other issue
              regarding to your items which to delivered, simply give it back to
              the delivery boy to return on the spot or if you have seen the
              order lately call on nearest Najeeb Pharmacy from which you have
              ordered to return or change the product. The delivery boy will get
              back to you and provide you the best without additional charges.`,
  },
  {
    imageSrc: "assets/imgs/theme/icons/icon-5.svg",
    title: "100% Satisfaction",
    description: `Customer Satisfaction is our main motive. If you have any query
              regarding to delivery or any other objection just tap the call
              button to the nearest pharmacy button, give them details and let
              the query to be resolved.`,
  },
  {
    imageSrc: "assets/imgs/theme/icons/icon-6.svg",
    title: "Great Daily Deal",
    description: ` A lot of discount offers and promotional offers are available only
              on online shopping from Najeeb Mart & Pharmacy. Discount and
              promotional offers can be availed by ordering through Najeeb Mart
              & Pharmacy application or from website.`,
  },
];

const Services = () => {
  return (
    <section className="text-center mb-50">
      <h2 className="title style-3 mb-40">What We Provide?</h2>
      <div className="row">
        {services.map((service) => (
          <div className="col-lg-4 col-md-6 mb-24">
            <div className="featured-card">
              <img src={service.imageSrc} alt={service.title} />
              <h4>{service.title}</h4>
              <p>{service.description}</p>
              <a href="#">Read more</a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
