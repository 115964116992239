/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Modal } from "src/components";
import { IProduct } from "src/models";
import Slider, { Settings } from "react-slick";
import config from "src/config/config";
import { Link } from "react-router-dom";
import Utils from "src/helper";
import { useMartCart } from "src/redux/hooks";

const productSliderSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  //asNavFor: ".slider-nav-thumbnails",
};

interface IProps {
  product?: IProduct;
  show?: boolean;
  onDismiss?: () => void;
}

const QuickView: React.FC<IProps> = ({ product, show, onDismiss }) => {
  const [quantity, setQuantity] = useState<string>("1");
  const { addCartItem } = useMartCart();

  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());
    }
  };

  const addToCart = () => {
    const q = Utils.getIntQuantity(quantity);
    addCartItem({
      product: product!,
      quantity: q,
    });
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(value);
  };
  return (
    <Modal show={show} onDismiss={onDismiss}>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
          <div className="detail-gallery">
            <span className="zoom-icon">
              <i className="fi-rs-search"></i>
            </span>

            <Slider {...productSliderSettings} className="product-image-slider">
              {product?.images?.map((image, index) => (
                <figure
                  className="border-radius-10 product-main-image-slider"
                  key={index}
                >
                  <img src={config.BASE_URL + image.path} alt="product image" />
                </figure>
              ))}
            </Slider>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="detail-info pr-30 pl-30">
            {(product?.discount || 0) > 0 && (
              <span className="stock-status out-stock">
                {Utils.roundTo1Digit(product?.discount || 0)}% off
              </span>
            )}
            <h3 className="title-detail">
              <Link
                to={`/shop/product-detail/${product?.id}`}
                className="text-heading"
              >
                {product?.title} {product?.qtyPerUnit}
              </Link>
            </h3>
            <div className="product-detail-rating">
              <div className="product-rate-cover text-end">
                <div className="product-rate d-inline-block">
                  <div
                    className="product-rating"
                    style={{ width: "90%" }}
                  ></div>
                </div>
                <span className="font-small ml-5 text-muted">
                  {" "}
                  (32 reviews)
                </span>
              </div>
            </div>
            <div className="clearfix product-price-cover">
              <div className="product-price primary-color float-left">
                <span className="current-price text-brand">
                  Rs
                  {Utils.getDicountPrice(
                    product?.price || 0,
                    product?.discount || 0
                  )}
                </span>
                {(product?.discount || 0) > 0 && (
                  <span>
                    <span className="save-price  font-md color3 ml-15">
                      {Utils.roundTo1Digit(product?.discount || 0)}% Off
                    </span>
                    <span className="old-price font-md ml-15">
                      Rs{product?.price}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <div className="detail-extralink mb-30">
              <div className="detail-qty border radius">
                <a onClick={decrementQuantity} className="qty-down">
                  <i className="fi-rs-angle-small-down"></i>
                </a>
                <span className="qty-val">
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={quantity}
                    onChange={onQuantityChange}
                    className="quantity-input"
                  />
                </span>
                <a onClick={incrementQuantity} className="qty-up">
                  <i className="fi-rs-angle-small-up"></i>
                </a>
              </div>
              <div className="product-extra-link2">
                <button
                  className="button button-add-to-cart"
                  onClick={addToCart}
                >
                  <i className="fi-rs-shopping-cart"></i>Add Item
                </button>
              </div>
            </div>
            {/* <div className="font-xs">
              <ul>
                <li className="mb-5">
                  Total:{" "}
                  <span className="text-brand">
                    Rs{" "}
                    {Utils.getDicountPrice(
                      product?.price || 0,
                      product?.discount || 0
                    ) * quantity}
                  </span>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuickView;
