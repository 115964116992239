/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IOrder } from "src/models";
import * as Types from "src/redux/types";

export default class OrderActions {
  public static load =
    (orders: IOrder[]) =>
    async (
      dispatch: DispatchType<Action<IOrder[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ORDERS_LOAD,
        payload: orders,
      });

  public static save =
    (order: IOrder) =>
    async (
      dispatch: DispatchType<Action<IOrder>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ORDERS_SAVE,
        payload: order,
      });

  public static delete =
    (id: number) =>
    async (
      dispatch: DispatchType<Action<number>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ORDERS_DELETE,
        payload: id,
      });

  public static update =
    (order: IOrder) =>
    async (
      dispatch: DispatchType<Action<IOrder>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ORDERS_EDIT,
        payload: order,
      });

  public static reset =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) =>
      dispatch({
        type: Types.ORDERS_RESET,
      });
}
