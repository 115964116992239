import React from "react";
import { Helmet } from "react-helmet";

const HelmetScript = () => {
  return (
    <Helmet>
      <script
        src="./../assets/js/vendor/modernizr-3.6.0.min.js"
        crossOrigin="anonymous"
      />
      <script src="./../assets/js/vendor/jquery-3.6.0.min.js" />
      <script src="./../assets/js/vendor/jquery-migrate-3.3.0.min.js" />
      <script src="./../assets/js/vendor/bootstrap.bundle.min.js" />
      <script src="./../assets/js/plugins/slick.js" />
      <script src="./../assets/js/plugins/jquery.syotimer.min.js" />
      <script src="./../assets/js/plugins/wow.js" />
      <script src="./../assets/js/plugins/jquery-ui.js" />
      <script src="./../assets/js/plugins/perfect-scrollbar.js" />
      <script src="./../assets/js/plugins/magnific-popup.js" />
      <script src="./../assets/js/plugins/select2.min.js" />
      <script src="./../assets/js/plugins/waypoints.js" />
      <script src="./../assets/js/plugins/counterup.js" />
      <script src="./../assets/js/plugins/jquery.countdown.min.js" />
      <script src="./../assets/js/plugins/images-loaded.js" />
      <script src="./../assets/js/plugins/isotope.js" />
      <script src="./../assets/js/plugins/scrollup.js" />
      <script src="./../assets/js/plugins/jquery.vticker-min.js" />
      <script src="./../assets/js/plugins/jquery.theia.sticky.js" />
      <script src="./../assets/js/plugins/jquery.elevatezoom.js" />
      <script src="./../assets/js/main.js" />
      <script src="./../assets/js/shop.js" />
    </Helmet>
  );
};

export default HelmetScript;
