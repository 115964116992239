import React, { useState } from "react";
import { IState } from "src/models";
import { StateService } from "../services";

const useStat = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<IState[]>([]);
  const getStates = async () => {
    try {
      setLoading(true);
      const response = await StateService.getStates();
      if (response.response) {
        setStats(response.response);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    stats,
    getStates,
  };
};

export default useStat;
