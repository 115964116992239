/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, FormInput } from "src/components";
import Utils from "src/helper";
import { useAuth } from "src/redux/hooks";
import OtpInput from "react-otp-input";
import { Color } from "src/resources";

const Login = () => {
  const {
    login,
    loading,
    loginResponse,
    sendOTP,
    setLoginResponse,
    setLoginUser,
  } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValid, setValid] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [toggleResend, setToggleResend] = useState(false);
  const [seconds, setSeconds] = useState<number>(60);
  const [generatedOTP, setGeneratedOTP] = useState<string>();

  useEffect(() => {
    setValid(Utils.isValidatePhoneNumber(phoneNumber));
  }, [phoneNumber]);

  const onSubmitHandler = async () => {
    if (isValid) {
      const number = Utils.getValidPhoneNumberWithCountryCode(phoneNumber);
      await login(number.fullNumber);
      //navigation.navigate('VerifyPhone');
    }
  };

  useEffect(() => {
    if (loginResponse) {
      sendOTPTopPhoneNumber(loginResponse.response?.phone);
    }
  }, [loginResponse]);

  React.useEffect(() => {
    if (seconds > 0)
      var timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    else setToggleResend(true);

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const sendOTPTopPhoneNumber = async (phoneNumber?: string) => {
    const gotp = Utils.generateOTP();
    setGeneratedOTP(gotp);
    setSeconds(60);
    await sendOTP(phoneNumber!, gotp);
  };

  const resendOTP = () => {
    if (loginResponse) {
      sendOTPTopPhoneNumber(loginResponse.response?.phone);
    }
  };
  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5"></i>Home
            </a>
            <span></span> Pages
            <span></span> My Account
          </div>
        </div>
      </div>
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 d-none d-lg-block">
                  <img
                    className="border-radius-15"
                    src="assets/imgs/page/login-1.png"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="login_wrap widget-taber-content background-white">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h1 className="mb-5">
                          {loginResponse === undefined
                            ? "Login"
                            : "Verify Phone "}
                        </h1>
                      </div>
                      {loginResponse === undefined ? (
                        <form>
                          <FormInput
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            type="text"
                            required
                            name="phonenumber"
                            placeholder="Enter your phone number *"
                            isError={phoneNumber !== "" && !isValid}
                            errorMessage="Invalid phone number"
                          />

                          <div className="form-group">
                            <Button loading={loading} onClick={onSubmitHandler}>
                              Log in
                            </Button>
                          </div>
                        </form>
                      ) : (
                        <form>
                          <div className="form-group">
                            Please enter 4 digit code sent on number{" "}
                            <strong>{phoneNumber}</strong>
                          </div>
                          <div className="form-group">
                            <OtpInput
                              separator={
                                <span>
                                  <strong>.</strong>
                                </span>
                              }
                              value={otp}
                              onChange={(text: string) => {
                                setOtp(text);
                                if (text === generatedOTP) {
                                  setLoginUser(
                                    loginResponse.response,
                                    loginResponse.accessToken
                                  );
                                }
                              }}
                              inputStyle={{
                                width: "4rem",
                                height: "3rem",
                                margin: "0 1rem",
                                fontSize: "2rem",
                                borderRadius: 4,
                                border: "1px solid rgba(0,0,0,0.3)",
                                color: "#000",
                                textAlign: "left",
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <p>
                              Resend verification code :{" "}
                              {toggleResend ? (
                                <a
                                  style={{ color: Color.primary }}
                                  onClick={resendOTP}
                                >
                                  Resend
                                </a>
                              ) : (
                                <a
                                  style={{ color: Color.primary }}
                                >{`${seconds} sec`}</a>
                              )}{" "}
                            </p>
                          </div>
                          <div className="form-group">
                            <Button
                              loading={loading}
                              onClick={() => setLoginResponse(undefined)}
                            >
                              Use Other Number
                            </Button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
