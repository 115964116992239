import React from "react";
import "./style.css";
import TimeLine from "./TimeLine";
interface IProps {
  status: string;
  declinedReason?: string;
}
const OrderTrackingDialog: React.FC<IProps> = ({ status, declinedReason }) => {
  return (
    <ul className="timeline">
      <TimeLine
        label="New"
        isActive={["New", "Pending", "In-Progress", "Delivered"].includes(
          status
        )}
        description="Your order has been received."
      />
      {status !== "Declined" ? (
        <>
          <TimeLine
            isActive={["Pending", "In-Progress", "Delivered"].includes(status)}
            label="Pending"
            description="Your order has been prepared."
          />
          <TimeLine
            isActive={["In-Progress", "Delivered"].includes(status)}
            label="In-Progress"
            description="Hang on! your order has on the way."
          />

          <TimeLine
            isActive={["Delivered"].includes(status)}
            label="Delivered"
            description="Wish you have interesting experience."
          />
        </>
      ) : (
        <TimeLine
          isActive={["Declined"].includes(status)}
          label="Declined"
          description={declinedReason || ""}
        />
      )}
    </ul>
  );
};

export default OrderTrackingDialog;
