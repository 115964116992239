import React, { useEffect } from "react";
import { useShops, useStore } from "src/redux/hooks";
import ContactMap from "./components/ContactMap";
import ContactUsForm from "./components/ContactUsForm";

const ContactUs = () => {
  const { shops, findAll, isLoaded } = useShops();

  useEffect(() => {
    (async () => {
      await findAll();
    })();
  }, [isLoaded]);
  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5"></i>Home
            </a>
            <span></span> Pages
            <span></span> Contact
          </div>
        </div>
      </div>
      <div className="page-content pt-50">
        {/* <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="row align-items-end mb-50">
                <div className="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
                  <h4 className="mb-20 text-brand">How can help you ?</h4>
                  <h1 className="mb-30">Let us know how we can help you</h1>
                  <p className="mb-20">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <h5 className="mb-20">01. Visit Feedback</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <h5 className="mb-20">02. Employer Services</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                    </div>
                    <div className="col-lg-6 mb-lg-0 mb-4">
                      <h5 className="mb-20 text-brand">
                        03. Billing Inquiries
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="mb-20">04.General Inquiries</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        <section className="container mb-50 d-none d-md-block">
          <div className="border-radius-15 overflow-hidden">
            <div id="map-panes" className="leaflet-map">
              <ContactMap />
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="mb-50">
                <div className="row mb-60">
                  {shops?.map((admin) => (
                    <div className="col-md-4 mb-4 mb-md-0">
                      <h4 className="mb-15 text-brand">{admin.storeName}</h4>
                      {admin.address}
                      <br />
                      {admin?.city} , Pakistan
                      <br />
                      <abbr title="Phone">Phone:</abbr> {admin.phone}
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="contact-from-area padding-20-row-col wow FadeInUp animated">
                      <h5 className="text-brand mb-10">Contact form</h5>
                      <h2 className="mb-10">Drop Us a Line</h2>
                      <p className="text-muted mb-30 font-sm">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                      <ContactUsForm />
                      <p className="form-messege"></p>
                    </div>
                  </div>
                  <div className="col-lg-4 pl-50 d-lg-block d-none">
                    <img
                      className="border-radius-15 mt-50"
                      src="assets/imgs/page/contact-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
