import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { useMartCart } from "src/redux/hooks";
import "./cartStyle.css";
import ItemCartMobile from "./ItemCartMobile";

const HeaderCart = () => {
  const { items, size, total, isEmpty } = useMartCart();
  return (
    <div className="header-action-icon-2">
      <Link className="mini-cart-icon" to="/cart">
        <img alt="Nest" src="/assets/imgs/theme/icons/icon-cart.svg" />
        <span className="pro-count blue">{size}</span>
      </Link>
      <span className="lable">Cart</span>
      <div className="cart-dropdown-wrap cart-dropdown-hm2">
        <ul>
          {!isEmpty ? (
            items.map((item, index) => (
              <ItemCartMobile cartItem={item} key={index} />
            ))
          ) : (
            <li>
              <h6 className="text-center">
                Look like you haven't add any item in your cart.
              </h6>
            </li>
          )}
        </ul>
        <div className="shopping-cart-footer">
          <div className="shopping-cart-total">
            <h4>
              Total <span>Rs{total}</span>
            </h4>
          </div>
          <div className="shopping-cart-button">
            <Link to="/cart" className="outline">
              View cart
            </Link>
            {!isEmpty && <Link to="/checkout">Checkout</Link>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCart;
