import { IAxiosResponse, IDeliveryCharge } from "src/models";
import axiosInstance from "./axios";

export default class DeliveryChargesService {
  private static readonly url: string = "/delivery-charges";
  public static async getDeliceryCharges(): Promise<
    IAxiosResponse<IDeliveryCharge>
  > {
    const response = await axiosInstance.get(`${this.url}`);
    return response.data;
  }
}
