/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { ICartItem } from "src/models";
import * as Types from "src/redux/types";

export default class MartCartActions {
  public static load =
    (cartItems: ICartItem[]) =>
    async (
      dispatch: DispatchType<Action<ICartItem[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_CART_LOAD,
        payload: cartItems,
      });

  public static addItem =
    (cartItem: ICartItem) =>
    async (
      dispatch: DispatchType<Action<ICartItem>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_CART_ADD_ITEM,
        payload: cartItem,
      });

  public static updateItem =
    (cartItem: ICartItem) =>
    async (
      dispatch: DispatchType<Action<ICartItem>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_CART_UPDATE_ITEM,
        payload: cartItem,
      });

  public static removeItem =
    (id: number) =>
    async (
      dispatch: DispatchType<Action<number>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_CART_REMOVE,
        payload: id,
      });

  public static clear =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) =>
      dispatch({
        type: Types.MART_CART_CLEAR,
      });
}
