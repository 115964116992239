/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import {
  IAddress,
  IProduct,
  IProductCategory,
  IProductPagination,
} from "src/models";
import * as Types from "src/redux/types";

export default class ProductPaginationActions {
  public static load =
    (pageData: IProductPagination) =>
    async (
      dispatch: DispatchType<Action<IProductPagination>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.PRODUCT_PAGE_LOAD,
        payload: pageData,
      });

  public static discountProductCategoryLoad =
    (productCategories: IProductCategory[]) =>
    async (
      dispatch: DispatchType<Action<IProductCategory[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_DISCOUNT_PRODUCT_CATEGORY_LOAD,
        payload: productCategories,
      });

  public static loadDiscountProducts =
    (products: IProduct[]) =>
    async (
      dispatch: DispatchType<Action<IProduct[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_DISCOUNT_PRODUCTS_LOAD,
        payload: products,
      });

  public static loadBestSellsProducts =
    (products: IProduct[]) =>
    async (
      dispatch: DispatchType<Action<IProduct[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_BEST_SELLS_PRODUCT_LOAD,
        payload: products,
      });
}
