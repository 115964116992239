import { useFormik } from "formik";
import React from "react";
import { useEmailSubscribe } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";
const SubscribeEmail = () => {
  const { loading, subscribe } = useEmailSubscribe();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await subscribe(
        values.email!,
        () => {
          swal(`Email successfully subscribed for newsletter!`, {
            icon: "success",
          });
        },
        (message: string) => {
          swal(`OOPS! ${message}!`, {
            icon: "error",
          });
        }
      );
    },
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;
  return (
    <form className="form-subcriber d-flex" onSubmit={handleSubmit}>
      <input
        type="email"
        placeholder="Your emaill address"
        {...getFieldProps("email")}
      />

      <button className="btn" type="submit">
        Subscribe
      </button>
    </form>
  );
};

export default SubscribeEmail;
