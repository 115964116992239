import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "..";
import { MartCategoryActions } from "../actions";
import { MartCategoryService } from "../services";

const useMartCategories = () => {
  const dispatch = useDispatch();
  const {
    martCategories: { isLoaded, categories },
    martWebsiteAuth: { isLoggedIn },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const findAll = async () => {
    try {
      setLoading(true);
      const response = await MartCategoryService.findAll();
      if (response.response) {
        dispatch(MartCategoryActions.load(response.response));
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };
  var perChunk = 7;
  var chunkCategories = categories.reduce((resultArray: any, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return {
    loading,
    isLoggedIn,
    isLoaded,
    categories,
    chunkCategories,
    findAll,
  };
};

export default useMartCategories;
