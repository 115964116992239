/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IAddress } from "src/models";
import * as Types from "src/redux/types";

export default class AddressActions {
  public static load =
    (addresses: IAddress[]) =>
    async (
      dispatch: DispatchType<Action<IAddress[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ADDRESSES_LOAD,
        payload: addresses,
      });

  public static save =
    (address: IAddress) =>
    async (
      dispatch: DispatchType<Action<IAddress>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ADDRESS_SAVE,
        payload: address,
      });

  public static delete =
    (id: number) =>
    async (
      dispatch: DispatchType<Action<number>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ADDRESS_DELETE,
        payload: id,
      });

  public static update =
    (address: IAddress) =>
    async (
      dispatch: DispatchType<Action<IAddress>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.ADDRESS_EDIT,
        payload: address,
      });

  public static reset =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) =>
      dispatch({
        type: Types.ADDRESS_RESET,
      });
}
