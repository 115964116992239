/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CategoryItem } from "src/components";
import config from "src/config/config";
import Utils from "src/helper";
import { ICategory } from "src/models";
import { useMartCategories, useProducts } from "src/redux/hooks";

interface IProps {
  selectedCategory?: ICategory;
}

const Sidebar: React.FC<IProps> = ({ selectedCategory }) => {
  const { discountProducts, isDiscountProductLoaded, loadDiscountProducts } =
    useProducts();
  const { categories, isLoaded, findAll } = useMartCategories();
  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await findAll();
      }
    })();
  }, [isLoaded]);

  useEffect(() => {
    (async () => {
      if (!isDiscountProductLoaded) {
        await loadDiscountProducts();
      }
    })();
  }, [isDiscountProductLoaded]);
  return (
    <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
      <div className="sidebar-widget widget-category-2 mb-30">
        <h5 className="section-title style-1 mb-30 wow fadeIn animated">
          Category
        </h5>
        <ul>
          {categories.map((category, index) => (
            <CategoryItem
              category={category}
              key={index}
              isActive={category.id === selectedCategory?.id}
            />
          ))}
        </ul>
      </div>
      {/* Fillter By Price */}
      {/* <div className="sidebar-widget price_range range mb-30">
        <h5 className="section-title style-1 mb-30 wow fadeIn animated">
          Fill by price
        </h5>
        <div className="price-filter">
          <div className="price-filter-inner">
            <div id="slider-range"></div>
            <div className="price_slider_amount">
              <div className="label-input">
                <span>Range:</span>
                <input
                  type="text"
                  id="amount"
                  name="price"
                  placeholder="Add Your Price"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="list-group">
          <div className="list-group-item mb-10 mt-10">
            <label className="fw-900">Color</label>
            <div className="custome-checkbox">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox1"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox1">
                <span>Red (56)</span>
              </label>
              <br />
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox2"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox2">
                <span>Green (78)</span>
              </label>
              <br />
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox3"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox3">
                <span>Blue (54)</span>
              </label>
            </div>
            <label className="fw-900 mt-15">Item Condition</label>
            <div className="custome-checkbox">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox11"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox11">
                <span>New (1506)</span>
              </label>
              <br />
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox21"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox21">
                <span>Refurbished (27)</span>
              </label>
              <br />
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox"
                id="exampleCheckbox31"
                value=""
              />
              <label className="form-check-label" htmlFor="exampleCheckbox31">
                <span>Used (45)</span>
              </label>
            </div>
          </div>
        </div>
        <a href="shop-grid-right.html" className="btn btn-sm btn-default">
          <i className="fi-rs-filter mr-5"></i> Fillter
        </a>
      </div> */}
      {/* Product sidebar Widget */}

      {Utils.getRandomProducts(discountProducts, 2).map((product, index) => {
        const { images, id, title, qtyPerUnit, discount } = product;
        const linkTo = `/shop/product-detail/${id}`;
        return (
          <div
            className={`banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none banner-bg-${index}`}
            style={{ marginTop: index === 1 ? 10 : 0 }}
          >
            <Link to={linkTo}>
              <img src={config.BASE_URL + images[0].path} alt="" />
            </Link>
            <div className="banner-text">
              <Link to={linkTo}>
                <span>{qtyPerUnit}</span>
                <h4>
                  Save {Utils.roundTo1Digit(discount)}% <br /> on{" "}
                  <span className="text-brand">{title}</span>
                  <br /> {qtyPerUnit}
                </h4>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
