import React from "react";
import { Link } from "react-router-dom";

const OurTeams = () => {
  return (
    <section className="mb-50">
      <h2 className="title style-3 mb-40 text-center">Our Team</h2>
      <div className="row">
        <div className="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
          <h6 className="mb-5 text-brand">Our Team</h6>
          <h1 className="mb-30">Meet Our Expert Team</h1>
          <p className="mb-30">comming soon</p>
          <p className="mb-30"></p>
          <Link to="/members" className="btn">
            View All Members
          </Link>
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="team-card">
                <img src="/assets/imgs/page/ceo_cofounder.jpg" alt="" />
                <div className="content text-center">
                  <h4 className="mb-5">Najeeb Ullah Khan</h4>
                  <span>CEO & Co-Founder</span>
                  <div className="social-network mt-20">
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-facebook-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-twitter-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-instagram-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-youtube-brand.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="team-card">
                <img src="assets/imgs/page/Co_founder.jpg" alt="" />
                <div className="content text-center">
                  <h4 className="mb-5">Umer Hayat Khan</h4>
                  <span>Co-Founder</span>
                  <div className="social-network mt-20">
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-facebook-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-twitter-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-instagram-brand.svg"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="assets/imgs/theme/icons/icon-youtube-brand.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeams;
