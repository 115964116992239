/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { ICategory } from "src/models";
import * as Types from "src/redux/types";

export default class MartCategoryActions {
  public static load =
    (categories: ICategory[]) =>
    async (
      dispatch: DispatchType<Action<ICategory[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.MART_CATEGORIES_LOAD,
        payload: categories,
      });
}
