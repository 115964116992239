/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import usePagination, { DOTS } from "./usePagination";
import classnames from "classnames";

interface IProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const Pagination: React.FC<IProps> = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
  className,
  hasNextPage,
  hasPrevPage,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange === undefined) return <p>No page founds</p>;

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination justify-content-start">
      {/* Left navigation arrow */}
      <li className="page-item">
        <a
          className="page-link"
          onClick={() => (hasPrevPage ? onPrevious() : undefined)}
        >
          <i className="fi-rs-arrow-small-left"></i>
        </a>
      </li>
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li className="page-item" key={pageNumber}>
              <a className="page-link dot" href="#">
                ...
              </a>
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li
            className={`page-item ${
              pageNumber === currentPage ? "active" : ""
            }`}
            key={pageNumber}
          >
            <a
              className="page-link"
              onClick={() => onPageChange(parseInt("" + pageNumber))}
            >
              {pageNumber}
            </a>
          </li>
        );
      })}

      <li className="page-item">
        <a className="page-link" onClick={() => (hasNextPage ? onNext() : {})}>
          <i className="fi-rs-arrow-small-right"></i>
        </a>
      </li>
    </ul>
  );
};

Pagination.defaultProps = {
  siblingCount: 1,
};

export default Pagination;
