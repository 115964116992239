import React from "react";

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: string;
}
const TextAreaInput: React.FC<IProps> = ({ label, error, ...inputProps }) => {
  return (
    <>
      <label>
        {label} {inputProps.required && <span className="required">*</span>}
      </label>
      <textarea className="form-control" {...inputProps} />
      {error && <span className="error">{error}</span>}
    </>
  );
};

export default TextAreaInput;
