import { IAdmin, IAxiosResponse } from "src/models";
import axiosInstance from "./axios";

export default class ShopService {
  private static readonly url: string = "/shops";
  public static async findAll(): Promise<IAxiosResponse<IAdmin[]>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }
}
