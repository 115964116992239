import React, { Fragment } from "react";
import { Link } from "react-router-dom";

interface IProps {
  pages?: string[];
}
const Breadcrumb: React.FC<IProps> = ({ pages }) => {
  return (
    <div className="page-header breadcrumb-wrap">
      <div className="container">
        <div className="breadcrumb">
          <Link to="/" rel="nofollow">
            <i className="fi-rs-home mr-5"></i>Home
          </Link>
          {pages?.map((page, index) => (
            <Fragment key={index}>
              <span></span> {page}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

Breadcrumb.defaultProps = {
  pages: [],
};

export default Breadcrumb;
