import React, { useEffect } from "react";
import { useStat } from "src/redux/hooks";

const Stats = () => {
  const { getStates, stats } = useStat();

  useEffect(() => {
    (async () => {
      await getStates();
    })();
  }, []);
  return (
    <section className="container mb-50 d-none d-md-block">
      <div className="row about-count">
        {stats.map((stat) => (
          <div className="col-lg-3  col-md-6 text-center">
            <h1 className="heading-1">
              <span className="count">{stat.count}</span>+
            </h1>
            <h4>{stat.title}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Stats;
