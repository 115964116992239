/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Pagination, Preloader } from "src/components";
import { useMartCategories, useProducts } from "src/redux/hooks";
import ItemDealProduct from "./components/ItemDealProduct";
import ItemProduct2 from "./components/ItemProduct2";
import Sidebar from "./components/Sidebar";
import qs from "qs";
import { ICategory, IProduct, ISubCategory } from "src/models";
import EmptyShop from "./components/EmptyShop";
import QuickView from "../product-detail/components/QuickView";

const Shop2 = () => {
  const { categories } = useMartCategories();
  const { search } = useLocation();
  const [category, setCategory] = useState<ICategory>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    ISubCategory[]
  >([]);
  const headerTitle = category ? category.title : "Shop";
  const {
    findAll,
    currentPage,
    totalItems,
    hasNextPage,
    hasPrevPage,
    pageSize,
    pageSizes,
    setPageSize,
    products,
    loading,
    discountProducts,
    loadDiscountProducts,
    isDiscountProductLoaded,
  } = useProducts();
  const [showPaggingOption, setShowPaggingOption] = useState<boolean>(false);
  const [quickViewProduct, setQuickViewProduct] = useState<IProduct>();

  const loadPageData = async (page: number) => {
    window.scrollTo(200, 200);
    const categoryId: any = parseInt("" + getQueryParamsObj("category"));
    const catId = isNaN(categoryId) ? 0 : categoryId;
    const searchQuery = getQueryParamsObj("search");
    const searchText = searchQuery !== undefined ? searchQuery.toString() : "";
    await findAll(
      page,
      pageSize,
      catId,
      selectedSubCategory.map((c) => c.id),
      searchText
    );
  };

  useEffect(() => {
    (async () => {
      const categoryId: any = parseInt("" + getQueryParamsObj("category"));
      const catId = isNaN(categoryId) ? 0 : categoryId;
      const searchQuery = getQueryParamsObj("search");
      const searchText =
        searchQuery !== undefined ? searchQuery.toString() : "";

      await findAll(
        currentPage,
        pageSize,
        catId,
        selectedSubCategory.map((c) => c.id),
        searchText
      );
    })();
  }, [currentPage, pageSize, search, selectedSubCategory]);

  const getQueryParamsObj = (key: string) => {
    return qs.parse(search.substring(1, search.length))[key];
  };

  useEffect(() => {
    const categoryId: any = parseInt("" + getQueryParamsObj("category"));
    const catId = isNaN(categoryId) ? 0 : categoryId;
    if (catId !== 0) {
      setCategory(categories.find((cat) => cat.id === categoryId));
    }
    return () => {
      setCategory(undefined);
    };
  }, [search]);

  useEffect(() => {
    if (category === undefined) {
      setSelectedSubCategory([]);
    }
    window.scrollTo(200, 200);
  }, [category]);

  const addOrRemoveSelectedSubCategory = (subCategory: ISubCategory) => {
    if (getIsSelectedSubCategory(subCategory)) {
      setSelectedSubCategory(
        selectedSubCategory.filter((c) => c.id !== subCategory.id)
      );
    } else {
      setSelectedSubCategory([...selectedSubCategory, subCategory]);
    }
  };

  const getIsSelectedSubCategory = (subCategory: ISubCategory): boolean => {
    return (
      selectedSubCategory.find((c) => c.id === subCategory.id) !== undefined
    );
  };

  const onQuickViewClick = (product: IProduct) => {
    setQuickViewProduct(product);
  };
  return (
    <main className="main">
      <div className="page-header mt-30 mb-50">
        <div className="container">
          <div className="archive-header">
            <div className="row align-items-center">
              <div className="col-xl-3">
                <h1 className="mb-15">{headerTitle}</h1>
                <div className="breadcrumb">
                  <Link to="/" rel="nofollow">
                    <i className="fi-rs-home mr-5"></i>Home
                  </Link>
                  <span></span> Shop
                  {headerTitle !== "Shop" && (
                    <>
                      <span></span> {headerTitle}
                    </>
                  )}
                </div>
              </div>
              {category && (
                <div className="col-xl-9 text-end d-none d-xl-block">
                  <ul className="tags-list">
                    {category?.SubCategories?.map((subcategory) => {
                      return (
                        <li
                          className={`hover-up ${
                            getIsSelectedSubCategory(subcategory)
                              ? "active"
                              : ""
                          }`}
                          key={subcategory.id}
                        >
                          <a
                            className={
                              getIsSelectedSubCategory(subcategory)
                                ? "active-subcategory"
                                : ""
                            }
                            href="#"
                            onClick={() =>
                              addOrRemoveSelectedSubCategory(subcategory)
                            }
                          >
                            <i className="fi-rs-cross mr-10"></i>
                            {subcategory.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-30">
        <div className="row flex-row-reverse">
          <div className="col-lg-4-5">
            <div className="shop-product-fillter">
              <div className="totall-product">
                <p>
                  We found <strong className="text-brand">{totalItems}</strong>{" "}
                  items for you!
                </p>
              </div>
              <div className="sort-by-product-area">
                <div
                  className={`sort-by-cover mr-10 ${
                    showPaggingOption ? "show" : ""
                  }`}
                >
                  <div
                    className="sort-by-product-wrap"
                    onClick={() => setShowPaggingOption(!showPaggingOption)}
                  >
                    <div className="sort-by">
                      <span>
                        <i className="fi-rs-apps"></i>Show:
                      </span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span>
                        {" "}
                        {pageSize} <i className="fi-rs-angle-small-down"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    className={`sort-by-dropdown ${
                      showPaggingOption ? "show" : ""
                    }`}
                  >
                    <ul>
                      {pageSizes.map((size, index) => (
                        <li key={index}>
                          <a
                            className={`${size === pageSize ? "active" : ""}`}
                            onClick={() => setPageSize(size)}
                          >
                            {size}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                  <div className="text-center">
                    <img src="assets/imgs/theme/loading.gif" alt="" />
                  </div>
                </div>
              </div>
            ) : !loading && products.length === 0 ? (
              <EmptyShop categoryTitle={headerTitle} />
            ) : (
              <div className="row product-grid">
                {products.map((product) => (
                  <ItemProduct2
                    product={product}
                    key={product.id}
                    onQuickViewClick={onQuickViewClick}
                  />
                ))}

                {/* end product card */}
              </div>
            )}

            {/*product grid*/}
            <div className="pagination-area mt-20 mb-20">
              <nav aria-label="Page navigation example">
                <Pagination
                  onPageChange={(page) => loadPageData(page)}
                  totalCount={totalItems}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  hasPrevPage={hasPrevPage}
                  hasNextPage={hasNextPage}
                />
              </nav>
            </div>
            {/* <section className="section-padding pb-5">
              <div className="section-title">
                <h3 className="">Deals Of The Day</h3>
                <a className="show-all" href="shop-grid-right.html">
                  All Deals
                  <i className="fi-rs-angle-right"></i>
                </a>
              </div>
              <div className="row">
                <ItemDealProduct />
                <ItemDealProduct />
                <ItemDealProduct />
                <ItemDealProduct />
              </div>
            </section> */}
            {/*End Deals*/}
          </div>
          <Sidebar selectedCategory={category} />
        </div>
      </div>
      <QuickView
        show={quickViewProduct !== undefined}
        onDismiss={() => setQuickViewProduct(undefined)}
        product={quickViewProduct}
      />
    </main>
  );
};

export default Shop2;
