/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { useProducts } from "src/redux/hooks";
import ItemBestSellProduct from "../../shop/components/ItemBestSellProduct";

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  arrows: true,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  prevArrow: (
    <span className="slider-btn slider-prev">
      <i className="fi-rs-arrow-small-left"></i>
    </span>
  ),
  nextArrow: (
    <span className="slider-btn slider-next">
      <i className="fi-rs-arrow-small-right"></i>
    </span>
  ),
  // appendArrows: appendArrowsClassName,
};
const BestSellsProducts = () => {
  const { bestSellsProducts, isBestSellsLoaded, loadBestSellsProducts } =
    useProducts();

  useEffect(() => {
    (async () => {
      if (!isBestSellsLoaded) {
        await loadBestSellsProducts(20);
      }
    })();
  }, [isBestSellsLoaded]);
  return (
    <section className="section-padding pb-5">
      <div className="container">
        <div className="section-title">
          <h3 className="">Daily Best Sells</h3>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="carausel-4-columns-cover arrow-center position-relative">
              <div
                className="slider-arrow slider-arrow-2 carausel-4-columns-arrow"
                id="carausel-4-columns-arrows"
              ></div>
              <Slider
                {...settings}
                className="carausel-4-columns carausel-arrow-center"
              >
                {bestSellsProducts.map((product) => (
                  <ItemBestSellProduct product={product} key={product.id} />
                ))}
              </Slider>
            </div>
            {/*End tab-content*/}
          </div>
          {/*End Col-lg-9*/}
        </div>
      </div>
    </section>
  );
};

export default BestSellsProducts;
