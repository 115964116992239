import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IProduct } from "src/models";
import { useProducts } from "src/redux/hooks";
import ItemProduct from "../../shop/components/ItemProduct";
import QuickView from "./QuickView";
import RelatedProductItem from "./RelatedProductItem";
interface IProps {
  productId: number;
  productCategoryId: number;
}
const RelatedProduct: React.FC<IProps> = ({ productCategoryId, productId }) => {
  const { findRelatedProducts, relatedProducts } = useProducts();
  const [quickViewProduct, setQuickViewProduct] = useState<IProduct>();
  useEffect(() => {
    (async () => {
      await findRelatedProducts(productCategoryId, productId);
    })();
  }, [productCategoryId, productId]);

  const onQuickViewClick = (product: IProduct) => {
    setQuickViewProduct(product);
  };

  return (
    <div className="row mt-60">
      <div className="col-12">
        <h2 className="section-title style-1 mb-30">Related products</h2>
      </div>
      <div className="col-12">
        <div className="row related-products">
          {relatedProducts?.map((product) => (
            <RelatedProductItem
              product={product}
              key={product.id}
              onQuickViewClick={onQuickViewClick}
            />
          ))}
        </div>
        <QuickView
          show={quickViewProduct !== undefined}
          onDismiss={() => setQuickViewProduct(undefined)}
          product={quickViewProduct}
        />
      </div>
    </div>
  );
};

export default RelatedProduct;
