import React from "react";
import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import config from "src/config/config";
import { useMartCategories } from "src/redux/hooks";
import "./css/category.css";

const CategorySlider = () => {
  const { categories } = useMartCategories();
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    autoplay: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <span className="slider-btn slider-prev">
        <i className="fi-rs-arrow-small-left"></i>
      </span>
    ),
    nextArrow: (
      <span className="slider-btn slider-next">
        <i className="fi-rs-arrow-small-right"></i>
      </span>
    ),
    // appendArrows: appendArrowsClassName,
  };
  return (
    <section className="popular-categories section-padding">
      <div className="container wow fadeIn animated">
        <div className="section-title">
          <div className="title">
            <h3>Shop by Categories </h3>
            {/* <a className="show-all" href="shop-grid-right.html">
              All Categories
              <i className="fi-rs-angle-right"></i>
            </a> */}
          </div>
          <div
            className="slider-arrow slider-arrow-2 flex-right carausel-8-columns-arrow"
            id="carausel-8-columns-arrows"
          ></div>
        </div>
        <div className="carausel-8-columns-cover position-relative">
          <Slider {...settings} className="carausel-8-columns">
            {categories.map((category) => (
              <div key={category.id}>
                <div className="card-1">
                  <figure className=" img-hover-scale overflow-hidden">
                    <Link to={`/shop?category=${category.id}`}>
                      <img src={config.BASE_URL + category.image.path} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to={`/shop?category=${category.id}`}>
                      {category.title}
                    </Link>
                  </h6>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default CategorySlider;
