import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import { HomeBanners } from "src/data";
import Utils from "src/helper";
import { useProducts } from "src/redux/hooks";
import "./css/banner.css";

const Banner = () => {
  const { discountProducts } = useProducts();
  return (
    <section className="banners mb-25">
      <div className="container">
        <div className="row">
          {Utils.getRandomProducts(discountProducts, 3).map(
            (product, index) => {
              const { images, id, title, qtyPerUnit, discount } = product;
              const isLastIndex = index === HomeBanners.length - 1;
              const className = isLastIndex
                ? "col-lg-4 d-md-none d-lg-flex"
                : "col-lg-4 col-md-6";
              return (
                <div className={className} key={index}>
                  <div
                    className={`banner-img wow fadeIn animated banner-bg-${index}`}
                  >
                    <img src={config.BASE_URL + images[0].path} alt="" />
                    <div className="banner-text">
                      <h4>
                        {title} {qtyPerUnit} <br />{" "}
                        {Utils.roundTo1Digit(discount)}% off
                      </h4>
                      <Link
                        to={`/shop/product-detail/${id}`}
                        className="btn btn-xs"
                      >
                        Shop Now <i className="fi-rs-arrow-small-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
