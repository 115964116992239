/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IProduct } from "src/models";
import { useMartCart, useMartWishlist } from "src/redux/hooks";

interface IProps {
  product: IProduct;
}
const ItemWishlist: React.FC<IProps> = ({ product }) => {
  const { id, title, qtyPerUnit, price, discount, images } = product;
  const { addCartItem, removeItem, isItemExistInCart } = useMartCart();
  const { removeWishlistItem } = useMartWishlist();
  const linkTo = `/product-detail/${id}`;

  const addOrRemoveCartItem = () => {
    if (isItemExistInCart(id)) {
      removeItem(id);
    } else {
      addCartItem({ product, quantity: 1 });
    }
  };
  return (
    <tr className="pt-30">
      <td className="custome-checkbox pl-30">
        <input
          className="form-check-input"
          type="checkbox"
          name="checkbox"
          id="exampleCheckbox1"
          value=""
        />
        <label className="form-check-label" htmlFor="exampleCheckbox1"></label>
      </td>
      <td className="image product-thumbnail pt-40">
        <img src={config.BASE_URL + images[0].path} alt="#" />
      </td>
      <td className="product-des product-name">
        <h6>
          <Link className="product-name mb-10" to={linkTo}>
            {title} {qtyPerUnit}
          </Link>
        </h6>
        <div className="product-rate-cover">
          <div className="product-rate d-inline-block">
            <div className="product-rating" style={{ width: "90%" }}></div>
          </div>
          <span className="font-small ml-5 text-muted"> (4.0)</span>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h3 className="text-brand">
          Rs{Utils.getDicountPrice(price, discount)}
        </h3>
        {discount > 0 && (
          <span className="line-through old-price font-md ml-15">{price}</span>
        )}
      </td>
      <td className="text-center detail-info" data-title="Stock">
        <span className="stock-status in-stock mb-0">In Stock</span>
      </td>
      <td className="text-right" data-title="Cart">
        <button className="btn btn-sm" onClick={addOrRemoveCartItem}>
          {isItemExistInCart(id) ? "Remove cart" : "Add to cart"}
        </button>
      </td>
      <td className="action text-center" data-title="Remove">
        <a onClick={() => removeWishlistItem(id)} className="text-body">
          <i className="fi-rs-trash"></i>
        </a>
      </td>
    </tr>
  );
};

export default ItemWishlist;
