/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IProduct } from "src/models";
import { useMartWishlist } from "src/redux/hooks";
interface IProps {
  product: IProduct;
  onQuickViewClick?: (product: IProduct) => void;
}
const ItemBestSellProduct: React.FC<IProps> = ({
  product,
  onQuickViewClick,
}) => {
  const { id, title, qtyPerUnit, images, price, discount, ProductCategory } =
    product;
  const { isWishlisted, addWishlistItem, removeWishlistItem } =
    useMartWishlist();
  const isProductWishlisted = isWishlisted(id);

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(id);
    } else {
      addWishlistItem(product);
    }
  };
  const linkTo = `/shop/product-detail/${id}`;
  return (
    <div>
      <div className="product-cart-wrap">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <Link to={linkTo}>
              <img
                className="default-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
              <img
                className="hover-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
            </Link>
          </div>
          <div className="product-action-1">
            <a
              aria-label="Quick view"
              className="action-btn small hover-up"
              data-bs-toggle="modal"
              onClick={() => onQuickViewClick?.(product)}
            >
              <i className="fi-rs-eye"></i>
            </a>
            <a
              aria-label="Add To Wishlist"
              className="action-btn small hover-up"
              onClick={addOrRemoveWishlist}
            >
              <i
                className={
                  isProductWishlisted ? "fa-solid fa-heart" : "fi-rs-heart"
                }
              ></i>
            </a>
            <a
              aria-label="Compare"
              className="action-btn small hover-up"
              href="shop-compare.html"
            >
              <i className="fi-rs-shuffle"></i>
            </a>
          </div>
          {discount > 0 && (
            <div className="product-badges product-badges-position product-badges-mrg">
              <span className="best">{Utils.roundTo1Digit(discount)}% off</span>
            </div>
          )}
        </div>
        <div className="product-content-wrap">
          <div className="product-category">
            <a href="shop-grid-right.html">{ProductCategory?.title}</a>
          </div>
          <h2>
            <Link to={linkTo}>
              {title} {qtyPerUnit}
            </Link>
          </h2>
          <div className="product-rate d-inline-block">
            <div className="product-rating" style={{ width: "80%" }}></div>
          </div>
          <div className="product-price mt-10">
            <span>Rs{Utils.getDicountPrice(price, discount)}</span>
            {discount > 0 && <span className="old-price">Rs{price}</span>}
          </div>

          <Link to={linkTo} className="btn w-100 hover-up">
            <i className="fi-rs-shopping-cart mr-5"></i>Add To Cart
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ItemBestSellProduct;
