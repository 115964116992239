import React, { useState } from "react";
import { IContactUs } from "src/models";
import { ContactUsService } from "../services";

const useContactUs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const sendMessage = async (
    contact: IContactUs,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      setLoading(true);
      const response = await ContactUsService.sendMessage(contact);
      if (response.response) {
        onSuccess();
      }
    } catch (err: any) {
      console.log(err);
      if (err && err.response && err.response.data) {
        onError(err.response.data.message);
      } else {
        onError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    sendMessage,
  };
};

export default useContactUs;
