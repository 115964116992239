/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMartCategories } from "src/redux/hooks";
import "./searchheaderstyle.css";

const HeaderSearch = () => {
  const navigate = useNavigate();
  const { categories, isLoaded, findAll } = useMartCategories();
  const [categoryId, setCategoryId] = useState<number>(-1);
  const [search, setSearch] = useState<string>("");
  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await findAll();
      }
    })();
  }, [isLoaded]);
  const onCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(parseInt(e.target.value));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (search !== "") {
      let path = `/shop?search=${search}`;
      if (categoryId !== -1) path = `${path}&category=${categoryId}`;
      navigate(path);
    }
  };
  return (
    <div className="search-style-2">
      <form onSubmit={onSubmit}>
        <select onChange={onCategoryChange} value={categoryId}>
          <option value={-1}>All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search for items..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
    </div>
  );
};

export default HeaderSearch;
