const API_KEY =
  process.env.REACT_APP_API_KEY ??
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWNrYWdlTmFtZSI6ImNvbS5uYWplZWIubWFydC5waGFybWFjeS5zaG9wcGluZy5vbmxpbmUuZnJlZS5kZWxpdmVyeS5ncm9jZXJ5IiwiaWF0IjoxNjAxNDYyNDYyfQ.J_UBlexExMk65-4d8kEL6uKL5Ka9FP3i6aU1WkWS2JQ";

const DEV = true;
const HOST = process.env.REACT_APP_API_URL ?? "https://api.najeebmart.com/";
const PORT = DEV ? 5000 : 8081;
const BASE_URL: string =
  process.env.REACT_APP_API_URL ?? "https://api.najeebmart.com/";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? "ws://localhost:8080/";

const config = {
  API_KEY,
  BASE_URL,
  HOST,
  PORT,
  SOCKET_URL,
};

console.log(config);
export default config;
