import { useFormik } from "formik";
import React, { useEffect } from "react";
import { MapComponent, MapComponent2, Modal, TextInput } from "src/components";
import { IAddress } from "src/models";
import { useAddress, useAuth } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";

interface IProps {
  currentAddress?: IAddress;
  show: boolean;
  onDismiss: () => void;
}

const AddOrEditAddress: React.FC<IProps> = ({
  currentAddress,
  show,
  onDismiss,
}) => {
  const { user } = useAuth();
  const { saveAddress, loading, editAddress } = useAddress();
  const initialValues: IAddress = {
    id: 0,
    name: user?.name || "",
    phone: user?.phone || "",
    address: "",
    city: "",
    email: "",
    latitude: 0,
    longitude: 0,
    apartment: "",
    isDefault: false,
    UserId: user?.id!,
    gender: "Mr",
    type: "Home",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    apartment: Yup.string().required("Apartment detail is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (currentAddress === undefined) {
        //save
        swal({
          title: `Are you sure to save this address?`,
          text: values.address,
          icon: "warning",
          buttons: [true, true],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await saveAddress(values, () => {
              swal(`Success! Your address has been saved successfully!`, {
                icon: "success",
              });
              setTimeout(() => {
                onDismiss();
              }, 200);
            });
          }
        });
      } else {
        swal({
          title: `Are you sure to edit this address?`,
          text: values.address,
          icon: "warning",
          buttons: [true, true],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await saveAddress(values, () => {
              swal(`Success! Your address has been edited successfully!`, {
                icon: "success",
              });
              setTimeout(() => {
                onDismiss();
              }, 200);
            });
          }
        });
      }
    },
  });

  const { errors, touched, values, setFieldValue } = formik;

  useEffect(() => {
    if (currentAddress) {
      formik.setValues({
        ...formik.values,
        ...currentAddress,
      });
    } else {
      formik.resetForm();
    }

    return () => {
      formik.resetForm();
    };
  }, [currentAddress]);

  const onAddressChange = (address: IAddress) => {
    formik.setValues({
      ...formik.values,
      ...address,
    });
  };

  const getFieldError = (fieldName: string) => {
    const hasError = errors[fieldName];
    const hastouched = touched[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };

  return (
    <Modal
      onDismiss={onDismiss}
      show={show}
      contentStyle={{ width: "70%", minHeight: "70%", height: "70%" }}
    >
      <h3>{currentAddress ? "Edit Address" : "Add Address"}</h3>
      <br />
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="display-flex">
                <div>
                  <input
                    checked={values.gender === "Mr"}
                    onClick={() => setFieldValue("gender", "Mr")}
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender"
                    value="Mr"
                  />{" "}
                  Mr
                </div>
                <div>
                  <input
                    checked={values.gender === "Mrs"}
                    onClick={() => setFieldValue("gender", "Mrs")}
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender"
                    value="Mrs"
                  />{" "}
                  Mrs
                </div>
                <div>
                  <input
                    checked={values.gender === "Miss"}
                    onClick={() => setFieldValue("gender", "Miss")}
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender"
                    value="Miss"
                  />{" "}
                  Miss
                </div>
              </div>
              <div className="display-flex">
                <div>
                  <input
                    checked={values.type === "Home"}
                    onClick={() => setFieldValue("type", "Home")}
                    className="form-check-input"
                    type="radio"
                    name="labelas"
                    id="labelas"
                    value="Home"
                  />{" "}
                  Home
                </div>
                <div>
                  <input
                    checked={values.type === "Work"}
                    onClick={() => setFieldValue("type", "Work")}
                    className="form-check-input"
                    type="radio"
                    name="labelas"
                    id="labelas"
                    value="Work"
                  />{" "}
                  Work
                </div>
                <div>
                  <input
                    checked={values.type === "Other"}
                    onClick={() => setFieldValue("type", "Other")}
                    className="form-check-input"
                    type="radio"
                    name="labelas"
                    id="labelas"
                    value="Other"
                  />{" "}
                  Other
                </div>
              </div>

              <div className="form-group col-md-6 col-xs-12">
                <TextInput
                  label="Name"
                  required
                  {...formik.getFieldProps("name")}
                />{" "}
                {getFieldError("name")}
              </div>
              <div className="form-group col-md-6 col-xs-12">
                <TextInput
                  label="Phone Number"
                  required
                  {...formik.getFieldProps("phone")}
                />
                {getFieldError("phone")}
              </div>
              <div className="form-group col-md-6 col-xs-12">
                <TextInput
                  label="City"
                  required
                  {...formik.getFieldProps("city")}
                />
                {getFieldError("city")}
              </div>
              <div className="form-group col-md-6 col-xs-12">
                <TextInput
                  label="House No/Apartment"
                  required
                  {...formik.getFieldProps("apartment")}
                />
                {getFieldError("apartment")}
              </div>
              <div className="form-group col-md-12">
                <label>
                  Address <span className="required">*</span>
                </label>
                <textarea rows={2} {...formik.getFieldProps("address")}>
                  {values.address}
                </textarea>
                {getFieldError("address")}
              </div>

              <div className="col-md-12">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-fill-out submit font-weight-bold"
                  name="submit"
                  value="Submit"
                >
                  {currentAddress ? "Edit Address" : "Save Address"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <MapComponent2
            defaultAddrress={values}
            onAddressChange={onAddressChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddOrEditAddress;
