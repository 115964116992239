import { useFormik } from "formik";
import React from "react";
import { TextInput, TextAreaInput } from "src/components";
import { useAppSelector } from "src/redux";
import { useAuth } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";

const DeleteAccountRequest = () => {
  const { user, sendDeleteRequest, loading } = useAuth();

  const name = user?.name || "";
  const initialValues = {
    phone: user?.phone,
    reason: "",
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(),
    reason: Yup.string().trim().required("Reason is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      swal({
        title: `Are you sure to send this request?`,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willEdit) => {
        if (willEdit) {
          await sendDeleteRequest(
            {
              ...values,
              UserId: user?.id!,
            },
            (response) => {
              swal(`Success! Your request has been submitted successfully!`, {
                icon: "success",
              });
            }
          );
        }
      });
    },
  });

  const { errors, touched, getFieldProps, handleSubmit } = formik;

  return (
    <div
      className="tab-pane fade"
      id="delete-account"
      role="tabpanel"
      aria-labelledby="delete-account-tab"
    >
      <div className="card">
        <div className="card-header">
          <h5>Delete Account Request</h5>
        </div>
        <div className="card-body">
          <form noValidate onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-md-12">
                <label>
                  Phone Number <span className="required">(Can't Change)</span>
                </label>
                <input
                  disabled
                  value={user?.phone}
                  required
                  className="form-control"
                  name="phone"
                  type="text"
                />
              </div>
              <div className="form-group col-12">
                <TextAreaInput
                  rows={5}
                  label="Reason"
                  style={{ minHeight: 100 }}
                  {...getFieldProps("reason")}
                  error={
                    errors.reason && touched.reason ? errors.reason : undefined
                  }
                />
              </div>

              <div className="col-md-12">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-fill-out submit font-weight-bold"
                  name="submit"
                  value="Submit"
                >
                  Send Request
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountRequest;
