import React, { useState } from "react";
import { EmailSubscribeService } from "../services";

const useEmailSubscribe = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const subscribe = async (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      setLoading(true);
      const response = await EmailSubscribeService.subscribe(email);
      if (response.response) {
        onSuccess();
      }
    } catch (err: any) {
      console.log(err);
      if (err && err.response && err.response.data) {
        onError(err.response.data.message);
      } else {
        onError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    subscribe,
  };
};

export default useEmailSubscribe;
