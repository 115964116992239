import React from "react";
import { Link } from "react-router-dom";
import { useMartWishlist } from "src/redux/hooks";
import { useAppSelector } from "../../../redux";
import HeaderCart from "./HeaderCart";
import HeaderSearch from "./HeaderSearch";
import "./style.css";

const MiddleHeader = () => {
  const { isLoggedIn } = useAppSelector((state) => state.martWebsiteAuth);
  const { size } = useMartWishlist();
  return (
    <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
      <div className="container">
        <div className="header-wrap">
          <div className="logo logo-width-1">
            <Link to="/">
              <img src="/assets/imgs/theme/logo512.png" alt="logo" />
            </Link>
          </div>
          <div className="header-right">
            <HeaderSearch />
            <div className="header-action-right">
              <div className="header-action-2">
                {/* <div className="search-location">
                  <form action="#">
                    <select className="select-active">
                      <option>Your Location</option>
                      <option>Alabama</option>
                      <option>Alaska</option>
                      <option>Arizona</option>
                      <option>Delaware</option>
                      <option>Florida</option>
                      <option>Georgia</option>
                      <option>Hawaii</option>
                      <option>Indiana</option>
                      <option>Maryland</option>
                      <option>Nevada</option>
                      <option>New Jersey</option>
                      <option>New Mexico</option>
                      <option>New York</option>
                    </select>
                  </form>
                </div> */}
                <div className="header-action-icon-2">
                  <Link to={isLoggedIn ? "/my-account" : "/login"}>
                    <img
                      className="svgInject"
                      alt="Nest"
                      src="/assets/imgs/theme/icons/icon-user.svg"
                    />
                  </Link>
                  <span className="lable ml-0">
                    {isLoggedIn ? "Account" : "Login"}
                  </span>
                </div>
                <div className="header-action-icon-2">
                  <Link to="/wishlist">
                    <img
                      className="svgInject"
                      alt="Nest"
                      src="/assets/imgs/theme/icons/icon-heart.svg"
                    />
                    <span className="pro-count blue">{size}</span>
                  </Link>
                  <span className="lable">Wishlist</span>
                </div>
                <HeaderCart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleHeader;
