import React from "react";
import Slider, { Settings } from "react-slick";

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  arrows: true,
  autoplay: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  prevArrow: (
    <span className="slider-btn slider-prev">
      <i className="fi-rs-arrow-small-left"></i>
    </span>
  ),
  nextArrow: (
    <span className="slider-btn slider-next">
      <i className="fi-rs-arrow-small-right"></i>
    </span>
  ),
  //appendArrows: appendArrowsClassName,
};

const aboutSliderImages = [
  "/assets/imgs/page/about-2.jpg",
  "/assets/imgs/page/about-3.jpg",
];

const Welcome = () => {
  return (
    <section className="row align-items-center mb-50">
      <div className="col-lg-6">
        <img
          src="assets/imgs/page/about-1.jpg"
          alt=""
          className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4"
        />
      </div>
      <div className="col-lg-6">
        <div className="pl-25">
          <h2 className="mb-30">Welcome to Nest</h2>
          <p className="mb-25">
            Najeeb Mart & Pharmacy is largest online food, grocery & medical
            store currently operating in Islamabad Pakistan. Najeeb Mart &
            Pharmacy provides the easiest & best way to purchase products of top
            brands like... Nestle, Ora Pure, Tapal, Vital, Dettol, Royal Natural
            Foods, Trisme, Axe, Rivaj UK, Keune, Revelon, Garnier, Olivia,
            Knorr, Rafhan, Lux, Dove, Lipton, L’Oreal, Coca Cola, Shan, Vim,
            Pepsi, Walls, Hico and many other top brands. With over 5000
            products and over a 200 brands in our catalogue, you will find
            everything you are looking for. Right from Rice and Dals, Spices and
            Seasonings to Packaged products, Beverages, Personal care products,
            frozen, and non-perishable groceries, health and beauty supplies,
            household, gift and baby items. All kinds of medicines and surgical
            instruments – we have it all in our online superstore.
          </p>
          <p className="mb-50">
            Free attainable Health Information. Get an in depth vision of
            medicine that is either needed or prescribed by doctor. Simply
            upload the prescription our pharmacist will analyze as they receive
            it, your medicines will be dispatched or you can consult pharmacist
            online through pharmacy number given on the website. Check our
            Pharmacy catalogue, order medicines through application or website
            in Islamabad with 24/7 free delivery. we have detailed drug
            information with pictures and its uses in a convenient way. Medicine
            Prices are shown accurately. Explore the wealth of trustworthy
            information regarding health issues that's designed to help you, to
            make better decisions about your health. Choose from a wide range of
            options in every category, exclusively handpicked to help you find
            the best quality available at the lowest prices. Your order will be
            delivered right to your doorstep, without any delivery charges
            anywhere in Islamabad. You can buy cash on delivery. We guarantee on
            time delivery, and the best quality!
          </p>

          <div className="carausel-2-columns-cover position-relative">
            <Slider {...settings} className="carausel-2-columns">
              {aboutSliderImages.map((image, index) => (
                <div key={index}>
                  <div className="slick-img " key={index}>
                    <img src={image} alt="" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
