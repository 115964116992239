import React from "react";
import { TailSpin } from "react-loader-spinner";
import "./styles.css";

type IProps = {
  loading?: boolean;
  loaderSize?: number;
  loadingColor?: string;
  isBlock?: boolean;
  isHoverUp?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<IProps> = ({
  loaderSize,
  loadingColor,
  loading,
  isHoverUp,
  isBlock,
  children,
  ...buttonProps
}) => {
  const classNames = [
    "btn btn-heading",
    isBlock ? "btn-block" : undefined,
    isHoverUp ? "hover-up" : undefined,
  ]
    .filter((c) => c !== undefined)
    .join(" ");
  return (
    <button className={classNames} {...buttonProps} disabled={loading}>
      <TailSpin
        visible={loading}
        color={loadingColor}
        height={loaderSize}
        width={loaderSize}
      />
      {loading && <>&nbsp;&nbsp;</>}
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  loaderSize: 20,
  loadingColor: "#ffffff",
  loading: false,
  isBlock: true,
  isHoverUp: true,
};

export default Button;
