import { IProduct } from "src/models";
import * as Types from "src/redux/types";

type State = {
  products: IProduct[];
  isLoaded: boolean;
};

const initialState: State = {
  products: [],
  isLoaded: false,
};

const MartWishlistReducer = (
  state = initialState,
  action: Action<IProduct[] | IProduct | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.MART_WISHLIST_LOAD: {
      const data = payload as IProduct[];

      return {
        ...state,
        products: data,
        isLoaded: true,
      };
    }

    case Types.MART_WISHLIST_ADD_ITEM: {
      const data = payload as IProduct;
      return {
        ...state,
        products: [...state.products, data],
      };
    }

    case Types.MART_WISHLIST_ITEM_REMOVE: {
      const id = payload as number;
      return {
        ...state,
        products: state.products.filter((product) => product.id !== id),
      };
    }

    case Types.MART_WISHLIST_CLEAR: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default MartWishlistReducer;
