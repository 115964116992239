/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Fragment, useEffect } from "react";
import { Breadcrumb } from "../../../components";
import HistoryPerformance from "./components/HistoryPerformance";
import OurTeams from "./components/OurTeams";
import Services from "./components/Services";
import Stats from "./components/Stats";
import Welcome from "./components/Welcome";

const About = () => {
  return (
    <main className="main pages">
      <Breadcrumb pages={["About Us"]} />

      <div className="page-content pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <Welcome />
              <Services />
              {/* <HistoryPerformance /> */}
            </div>
          </div>
        </div>
        <Stats />
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <OurTeams />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
