import { IAuthPayload, IUser } from "src/models";
import * as Types from "src/redux/types";

type State = {
  user?: IUser;
  token?: string;
  isLoggedIn: boolean;
};

const initialState: State = {
  isLoggedIn: false,
  token: undefined,
  user: undefined,
};

const AuthReducer = (
  state = initialState,
  action: Action<IAuthPayload | IUser>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.LOGIN_USER: {
      const data = payload as IAuthPayload;
      return {
        ...state,
        isLoggedIn: true,
        token: data?.token,
        user: data?.user,
      };
    }

    case Types.LOGIN_UPDATE: {
      const data = payload as IUser;
      return { ...state, user: data };
    }
    case Types.LOGOUT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
