import React, { useEffect, useRef, useState } from "react";
import { Map, GoogleApiWrapper, IProvidedProps } from "google-maps-react";
import ReactDOM from "react-dom";

interface ILatLng {
  lat: number;
  lng: number;
}

interface IProps extends IProvidedProps {
  zoom?: number;
  initialCenter?: ILatLng;
  centerAroundCurrentLocation?: boolean;
  visible?: boolean;
}
const mapStyles = {
  position: "relative",
  width: "48%",
  height: "82%",
};

const MapComponent: React.FC<IProps> = ({ ...mapProps }) => {
  const refs = useRef<any>(undefined);
  const [currentLocation, setCurrentLocation] = useState<ILatLng>();

  useEffect(() => {
    if (mapProps.initialCenter) {
      setCurrentLocation({
        lat: mapProps.initialCenter.lat,
        lng: mapProps.initialCenter.lng,
      });
    }
  }, [mapProps.initialCenter]);

  useEffect(() => {
    if (mapProps.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const coords = pos.coords;
          setCurrentLocation({
            lat: coords.latitude,
            lng: coords.longitude,
          });
        });
      }
    }
  }, [mapProps.centerAroundCurrentLocation]);

  useEffect(() => {
    loadMap();
  }, [currentLocation]);

  const loadMap = () => {
    if (mapProps && mapProps.google && currentLocation) {
      // checks if google is available
      const { google } = mapProps;
      const maps = google.maps;

      const mapRef = refs.current.map;

      // reference to the actual DOM element
      const node = ReactDOM.findDOMNode(mapRef);

      let { zoom } = mapProps;
      const { lat, lng } = currentLocation;
      const center = new maps.LatLng(lat, lng);

      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
        }
      );

      // maps.Map() is constructor that instantiates the map
      refs.current.map = new maps.Map(node as any, mapConfig);
    }
  };
  return (
    <Map
      ref={refs}
      google={mapProps.google}
      style={mapStyles}
      initialCenter={currentLocation}
    />
  );
};

MapComponent.defaultProps = {
  zoom: 14,
  initialCenter: {
    lat: -1.2884,
    lng: 36.8233,
  },
  centerAroundCurrentLocation: false,
  visible: true,
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCCCwQrTau4RBV05yT8ndLsC1P0er8qetU",
})(MapComponent);
