/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IProduct } from "src/models";
import { useMartWishlist } from "src/redux/hooks";
interface IProps {
  product: IProduct;
  onQuickViewClick?: (product: IProduct) => void;
}

const ItemProduct: React.FC<IProps> = ({ product, onQuickViewClick }) => {
  const { id, title, qtyPerUnit, images, price, discount, ProductCategory } =
    product;
  const { isWishlisted, addWishlistItem, removeWishlistItem } =
    useMartWishlist();
  const isProductWishlisted = isWishlisted(id);

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(id);
    } else {
      addWishlistItem(product);
    }
  };
  const linkTo = `/shop/product-detail/${id}`;
  return (
    <div className="col-lg-1-5 col-md-4 col-12 col-sm-6" key={id}>
      <div className="product-cart-wrap wow fadeIn animated mb-30">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <Link to={linkTo}>
              <img
                className="default-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
              <img
                className="hover-img"
                src={config.BASE_URL + images[0].path}
                alt=""
              />
            </Link>
          </div>
          <div className="product-action-1">
            <a
              aria-label="Add To Wishlist"
              className="action-btn"
              onClick={addOrRemoveWishlist}
            >
              <i
                className={
                  isProductWishlisted ? "fa-solid fa-heart" : "fi-rs-heart"
                }
              ></i>
            </a>
            <a
              aria-label="Compare"
              className="action-btn"
              href="shop-compare.html"
            >
              <i className="fi-rs-shuffle"></i>
            </a>
            <a
              aria-label="Quick view"
              className="action-btn"
              data-bs-toggle="modal"
              onClick={() => onQuickViewClick?.(product)}
            >
              <i className="fi-rs-eye"></i>
            </a>
          </div>
          {discount > 0 && (
            <div className="product-badges product-badges-position product-badges-mrg">
              <span className="best">{Utils.roundTo1Digit(discount)}% off</span>
            </div>
          )}
        </div>
        <div className="product-content-wrap">
          <div className="product-category">
            <Link
              to={`/shop?category=${ProductCategory?.SubCategory?.Category?.id}`}
            >
              {ProductCategory?.SubCategory?.Category?.title}
            </Link>
          </div>
          <h2>
            <Link to={linkTo}>
              {title} {qtyPerUnit}
            </Link>
          </h2>
          <div className="product-rate-cover">
            <div className="product-rate d-inline-block">
              <div className="product-rating" style={{ width: "90%" }}></div>
            </div>
            <span className="font-small ml-5 text-muted"> (4.0)</span>
          </div>
          <div>
            <span className="font-small text-muted">{qtyPerUnit}</span>
          </div>
          <div className="product-card-bottom">
            <div className="product-price">
              <span>Rs{Utils.getDicountPrice(price, discount)}</span>
              {discount > 0 && <span className="old-price">Rs{price}</span>}
            </div>
            <div className="add-cart">
              <Link className="add" to={linkTo}>
                <i className="fi-rs-shopping-cart mr-5"></i>Add{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemProduct;
